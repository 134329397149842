/*footer*/
footer {
    background-image: url('../img/footer-bg.jpg');
    font-size: 14px;
}
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #7c7f78;
    padding-top: 38px;
    padding-bottom: 27px;
}
.footer a {
    color: #7c7f78;
}
.footer a:hover {
    color: #cde449;
    text-decoration: none;
}
.footer-social-item {
    display: flex;
    align-items: center;
    height: 33px;
    padding-left: 26px;
    padding-right: 26px;
    white-space: nowrap;
}
.footer-social-item:not(:first-child) {
    border-left: 1px solid #cde449;
}
.footer-social-item img {
    width: 35px;
    margin-right: 15px;
}
.footer-social {
    display: flex;
    align-items: center;
}
.footer-privacy-policy {
    border-bottom: 1px solid #cde449;
    padding-bottom: 5px;
}
.footer-logo {
    height: 25px;
}
@media screen and (max-width:1500px) {
    .footer {
        flex-direction: column;
    }
    .footer-social {
        order: 1;
        margin-bottom: 15px;
    }
    .footer-privacy-policy {
        order: 2;
        margin-bottom: 15px;
    }
    .footer-copyright {
        order: 3;
        margin-bottom: 15px;
    }
    .footer-logo {
        order: 4;
    }
}
@media screen and (max-width:650px) {
    .footer-social {
        flex-direction: column;
    }
    .footer-social-item:not(:first-child) {
        border-left: 1px solid transparent;
    }
}

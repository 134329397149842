/*blog page*/
.blog-page {
    margin-bottom: 110px;
}
.blog-card {
    margin-bottom: 100px;
    cursor: pointer;
}
.blog-card:hover .blog-card-description {
    color: #fff;
    background-color: transparent;
}
.blog-card:hover .blog-card-subtitle {
    color: #fff;
}
.blog-card:hover {
    .blackout {
        opacity: .4;
    }
    .btn-light {
        background-color: #cde449;
    }
    .btn-light span:first-child::before,
    .btn-light span:first-child::after,
    .btn-light span:last-child::before,
    .btn-light span:last-child::after {
        background-color: #b1c82e;
    }
    .blog-card-description::before {
        position: absolute;
        content: url('../img/material/lines2.png');
        top: -5%;
        right: 0;
        left: 35%;
    }
    .blog-card-description::after {
        position: absolute;
        content: url('../img/material/lines2.png');
        left: 0;
        top: -10%;
        right: 30%;
    }
}
.blog-card-wrapper {
    position: relative;
    margin-bottom: 16px;
    height: 500px;
    
    overflow: hidden;

}
.blog-card-img {
    height: 100%;
    background-size: cover;
}
.blog-wrapper {
    display: flex;
    flex-wrap: wrap;
}
.blog-card-description {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(205, 228, 73, .7);
    height: 140px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 27px;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}
.blog-card-title {
    font-size: 25px;
    line-height: 30px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Harmonia Sans Pro Cyr Semi Bd';
    padding-bottom: 17px;
}
.blog-card-subtitle {
    font-family: 'Harmonia Sans Pro Cyr';
    line-height: 25px;
    color: #6c6b5f;
}
.blog-card .btn-light {
    width: 250px;
    text-align: center;
}

@media screen and (max-width:576px) {
    .blog-card-description {
        height: auto;
    }
}
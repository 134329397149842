/*cases page*/
.blog-cases {
    margin-bottom: 70px;
    max-width: 520px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    padding-top: 20px;
    overflow: hidden;
    padding-right: 20px;
    cursor: pointer;
}
.blog-cases-wrapper::before {
    position: absolute;
    content: url('../img/material/lines3.png');
    top: -20px;
    right: -20px;
}
.blog-cases-img {
    height: 376px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-bottom: 23px;
    position: relative;
    overflow: hidden;
}
.blog-cases-wrapper {
    max-width: 490px;
    position: relative;
}
.blog-cases-description {
    margin-bottom: 23px;
}
.blog-cases .btn-light {
    width: 250px;
    text-align: center;
}
.blog-cases:hover {
    .blackout {
        opacity: .4;
    }
    .blog-cases-img:before {
        position: absolute;
        content: url('../img/material/lines3.png');
        top: 13px;
        right: 22px;
        z-index: 5;
    }
    .btn-light {
        background-color: #cde449;
    }
    .btn-light span:first-child::before,
    .btn-light span:first-child::after,
    .btn-light span:last-child::before,
    .btn-light span:last-child::after {
        background-color: #b1c82e;
    }
}

@media screen and (max-width:991px) {
    .blog-cases {
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (max-width:520px) {
    .blog-cases {
        max-height: 720px;
        width: 300px;
    }
    .blog-cases-img {
        height: 220px;
    }
}
/*services page*/
.work {
    // overflow: hidden;
}
.img-rotation-1 {
    position: absolute;
    top: -3%;
    left: 50%;
    animation: rotateimg 35s linear infinite;
}
.img-rotation-3 {
    position: absolute;
    top: 68%;
    left: 92%;
    animation: rotateimg 15s linear infinite;
}
.img-rotation-2 {
    position: absolute;
    top: 33%;
    left: 95%;
    animation: rotateimg 20s linear infinite;
}
.img-rotation-4 {
    position: absolute;
    top: 97%;
    left: 50%;
    animation: rotateimg 15s linear infinite;
}
.img-rotation-5 {
    position: absolute;
    top: 84%;
    left: 13%;
    animation: rotateimg 10s linear infinite;
}
.img-rotation-6 {
    position: absolute;
    top: 41%;
    left: -2%;
    animation: rotateimg 10s linear infinite;
}
.rotation-3 {
    position: absolute;
    left: 9%;
    top: 10%;
    animation: rotate 15s linear infinite;
    border-radius: 50%;
    height: 350px;
    width: 350px;
}
.rotation-1 {
    position: absolute;
    left: 4%;
    top: 4%;
    animation: rotate 35s linear infinite;
    border-radius: 50%;
    height: 400px;
    width: 400px;
}
.rotation-2 {
    position: absolute;
    left: 4%;
    top: 4%;
    animation: rotate 20s linear infinite;
    border-radius: 50%;
    height: 400px;
    width: 400px;
}
.rotation-4 {
    position: absolute;
    left: 4%;
    top: 4%;
    animation: rotate 15s linear infinite;
    border-radius: 50%;
    height: 400px;
    width: 400px;
}
.rotation-5 {
    position: absolute;
    left: 4%;
    top: 4%;
    animation: rotate 10s linear infinite;
    border-radius: 50%;
    height: 400px;
    width: 400px;
}
.rotation-6 {
    position: absolute;
    left: 4%;
    top: 4%;
    animation: rotate 10s linear infinite;
    border-radius: 50%;
    height: 400px;
    width: 400px;
}
@keyframes rotate {
    from { transform: rotate(0deg); }
    to   {  transform: rotate(360deg); }
}
@keyframes rotateimg {
    0% { transform: rotate(0); }
    25% { transform: rotate(-90deg); }
    50% { transform: rotate(-180deg); }
    75% { transform: rotate(-270deg); }
    100%   {  transform: rotate(-360deg); }
}
.work-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
}
.work-wrapper:after {
    position: absolute;
    content: url('../img/material/lines-green.png');
    bottom: -17%;
    left: -2%;
}
.work-img {
    position: relative;
    height: 100%;
}
.mouse-parallax-bg {
    position: absolute;
    top: 0;
    left: 0;
}
.work-img p {
    position: absolute;
    font-size: 60px;
    line-height: 60px;
    color: #afc62c;
    transform: rotate(-90deg) translate(-50%, -250%);
    white-space: nowrap;
    top: 0;
    left: 0;
    font-weight: 900;
}
.work-img:before {
    position: absolute;
    content: url('../img/material/lines-black.png');
    bottom: 17%;
    transform: translateY(150%);
    left: 75px;
}
.work-list-title {
    color: #afc62c;
    font-size: 40px;
    line-height: 60px;
    text-transform: uppercase;
    letter-spacing: .7px;
    font-weight: 600;
    padding-left: 35px;
    position: relative;
}
.work-list-title::before {
    position: absolute;
    content: '';
    left: 0;
    width: 24px;
    height: 1px;
    background-color: #afc62c;
    top: 50%;
}
.work-list-text {
    background-color: #f5f6ec;
    font-size: 20px;
    line-height: 30px;
    padding: 35px 40px;
    position: relative;
    z-index: 5;
}
.work li:last-child .work-list-text::before {
    display: none;
}
.work-list-text::before {
    position: absolute;
    content: url('../img/arrow-down.png');
    transform: translateX(-100%);
    left: -65px;
    bottom: -10px;
}
.work ol {
    counter-reset: myCounter;
    max-width: 950px;
    max-width: 800px;
}

.work li {
    list-style: none;
    position: relative;
    margin-bottom: 60px;
}

.work li:before {
    position: absolute;
    counter-increment: myCounter;
    content: counter(myCounter);
    color: #2b272e;
    font-size: 85px;
    line-height: 60px;
    height: 70px;
    z-index: 5;
    background: #fff;
    transform: translate(-100%, 48%);
    left: -51px;
    z-index: 10;
}

.work li:after {
    position: absolute;
    content: '';
    top: 0;
    transform: translate(-100%, 14%);
    left: -30px;
    width: 90px;
    height: 90px;
    border: 1px solid #2b272e;
    border-radius: 50%;
    background-color: #fff;
    z-index: 5;
}
.commercial {
    padding-bottom: 110px;
    background-color: #e9eae1;
    padding-top: 70px;
    position: relative;
    overflow: hidden;
}
.commercial:before {
    position: absolute;
    content: url('../img/material/lines2.png');
    right: 0;
    bottom: 132px;
    right: -13%;
}
.commercial:after {
    position: absolute;
    content: url('../img/material/lines2.png');
    right: 0;
    bottom: 110px;
    right: -18%;
}
.commercial h3 {
    font-size: 40px;
    line-height: 60px;
    font-weight: 900;
    letter-spacing: 2.4px;
}
.commercial-contact {
    font-size: 25px;
    color: #2b272e;
    line-height: 30px;
    display: flex;
    font-weight: 700;
}
.commercial-description {
    margin-bottom: 27px;
    line-height: 30px;
}
.commercial-contact img {
    margin-right: 22px;
}
.commercial-form {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    z-index: 5;
    position: relative;
    margin-left: 170px;
    width: 465px;
}
.commercial-form::before {
    position: absolute;
    content: '';
    border: 20px solid transparent;
    border-right: 20px solid #cde449;
    border-bottom: 20px solid #cde449;
    bottom: 0;
    right: 0;
}
.commercial-wrapper {
    display: flex;
}
.commercial-block {
    max-width: 806px;
}
.commercial-form-header {
    padding: 33px 40px 27px 40px;
    font-size: 30px;
    color: #fff;
    font-weight: 900;
    line-height: 40px;
    background-color: #a6b165;
    text-transform: uppercase;
    letter-spacing: 3px;
}
.commercial-form-input{
    border: none;
    border-bottom: 1px solid #272622;
    height: 30px;
    margin-top: 35px;
    margin-left: 35px;
    margin-right: 43px;
}
.commercial-form-input::placeholder {
    color:  #272622;
    font-size: 18px;
    font-weight: 600;
    line-height: 60px;
    padding: 8px 25px;
}
.commercial-form textarea {
    border: 1px solid #272622;
    padding: 11px 23px;
    margin-top: 55px;
    margin-bottom: 45px;
    height: 158px;
    resize: none;
    margin-left: 35px;
    margin-right: 43px;
}
.commercial-form textarea::placeholder {
    color:  #272622;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
}
.commercial-list-header {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    color: #afc62c;
    text-transform: uppercase;
    padding-top: 70px;
    padding-bottom: 35px;
    position: relative;
}
.commercial-list-header:before {
    position: absolute;
    content: url('../img/material/lines2.png');
    top: 0;
    left: -22%;
}
.commercial-list-header:after {
    position: absolute;
    content: url('../img/material/lines2.png');
    top: 20px;
    left: 24px;
}
.commercial-form .btn-light {
    margin-left: 35px;
    margin-right: 43px;
    max-width: 250px;
    margin-bottom: 72px;
}
.commercial-list ul {
    position: relative;
}
.commercial-list ul:before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    width: 30px;
    background-image: url('../img/material/pattern_lines.png');
}
.commercial-list li {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    padding-bottom: 45px;
    padding-left: 75px;
    position: relative;
}
.commercial-list li:last-child {
    padding-bottom: 15px;
}
.commercial-list li:before {
    position: absolute;
    content: url('../img/arrow-right-green.svg');
    left: 20px;
}
.main-img {
    position: relative;
    height: 100%;
}
.main-img > img {
    min-width: 380px;
    width: 100%;
}
.pulse {
    position: absolute;
    left: 10%;
    bottom: 0;
    top: 4%;
    animation: pulse 2.5s infinite;
}
@keyframes pulse {
    0% {
      transform: scale(.7);
    }
    50% {
        transform: scale(.8);
    }
    100% {
        transform: scale(.7);
    }
  }
@media screen and (max-width:1460px) {
    .work-wrapper {
        flex-direction: column;
    }
    .work ol {
        margin-left: auto;
        margin-right: auto;
        max-width: 70%;
    }
    .work-img:before {
        top: -50%;
        bottom: unset;
        left: 350px;
    }
    .work-img p {
        transform: unset;
        top: 30%;
    }
}
@media screen and (max-width:1199px) {
    .pulse {
        position: absolute;
        left: 10%;
        bottom: 0;
        top: 5%;
    }
}
@media screen and (max-width:1020px) {
    .commercial-wrapper {
        flex-direction: column;
    }
    .commercial-form {
        width: 80%;
        margin-top: 50px;
        margin-left: auto;
        margin-right: auto;
    }
}
@media screen and (max-width:767px) {
    .main-text {
        margin-right: 0;
    }
    .main-img {
        display: none;
    }
    .work-img {
        display: none;
    }
}
@media screen and (max-width:574px) {
    .commercial-form {
        width: 100%;
    }
    .work-img p {
        white-space: unset;
    }
    .work ol {
        max-width: 100%;
        padding-left: 0;
    }
    .work li {
        margin-top: 180px;
    }
    .work li:before {
        transform: none;
        left: 22px;
        top: -26%;
        height: 75px;
    }
    .work-list-title {
        padding-left: 0;
        padding-top: 10px;
    }
    .work li:after {
        transform: none;
        left: 0;
        top: -33%;
    }
    .work-list-title::before {
        display: none;
    }
}
@media screen and (max-width:420px) {
    .work-img:before {
        top: -60%;
        right: -30px;
    }
    .work li:before {
        left: 22px;
        top: -28%;
        height: 75px;
    }
    .work-list-title {
        font-size: 30px;
        line-height: 40px;
    }
    .commercial-form-input {
        margin-left: 15px;
        margin-right: 15px;
    }
    .commercial-form textarea {
        margin-left: 15px;
        margin-right: 15px;
    }
    .commercial-form .btn-light {
        margin-left: 15px;
        margin-right: 15px;
    }
    .commercial h3 {
        font-size: 30px;
        line-height: 40px;
    }
}
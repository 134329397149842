/*main page*/
.banner {
    padding-top: 335px;
    padding-bottom: 150px;
    background-size: cover;
    position: relative;
    max-height: 1062px;
    max-height: 100vh;
    height: 100vh;
    overflow: hidden;
}
.banner-text {
    position: absolute;
    color: #fff;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 60px;
    letter-spacing: 2.6px;
    right: 0;
    top: 45%;
    transform: rotate(-90deg);
    cursor: pointer;
}
.banner-video .banner-text,
.banner-info .banner-text {
    right: 4%;
}
.banner-video .banner-text::before,
.banner-info .banner-text::before {
    left: -85%;
}
.banner-video:before,
.banner-info:before {
    position: absolute;
    content: '';
    left: 0;
    right: 7%;
    bottom: 15%;
    height: 1px;
    background-color: #afc62c;
}
.banner-video:after,
.banner-info:after {
    position: absolute;
    content: '';
    right: -7%;
    bottom: 15%;
    width: 1px;
    background-color: transparent;
    height: 50%;
    width: 14%;
    border-top: 1px solid #afc62c;
    border-left: 1px solid #afc62c;
}
.banner-text::before {
    position: absolute;
    content: '';
    width: 1px;
    height: 236px;
    background-color: #cde449;
    transform: rotate(-90deg);
    left: -50%;
    top: -145%;
}
.banner-video {
    height: 100vh;
    // min-height: 1050px;
}
.banner-info {
    background-image: url(../img/bg-info.png);
    position: relative;
    display: flex;
    padding-top: 225px;
    align-items: center;
    padding-bottom: 270px;
}
.banner-info-header {
    font-size: 40px;
    line-height: 60px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    color: #cde449;
    font-weight: 900;
    margin-bottom: 33px;
}

.banner-info p {
    line-height: 30px;
}
.banner-info-text {
    max-width: 729px;
    color: #fff;
    padding: 40px;
    max-height: 610px;
    position: relative;
    background-color: #fff;
    background-image: url(../img/bg-info.png);
}
.banner-info-text::before {
    position: absolute;
    content: url('../img/dialog.png');
    top: 0;
    left: 0;
}
.banner-info-author {
    color: #afc62c;
    font-size: 25px;
    line-height: 25px;
    text-transform: uppercase;
    position: absolute;
    bottom: -6%;
    left: 11%;
}
.banner-info-author:before {
    position: absolute;
    content: url(../img/small-logo.png);
    top: 50%;
    bottom: 0;
    left: -11%;
    right: 0;
}
.banner-info-author p {
    margin-bottom: 0;
}
.banner-info-img {
    z-index: 5;
}
.banner-info-img img {
    // width: 690px!important;
    width: 649px!important;
    width: 600px!important;
}
.overlay {
    position: absolute;
    content: '';
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 100%);
    opacity: 0.3;
    left: 0;
    bottom: 0;
    right: 0;
}
.banner h1 {
    font-size: 65px;
    font-family: "Kelson Sans";
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 6px 4px #cde449;
    color: transparent;
    -webkit-text-stroke: 2px #504e3b;
    padding-bottom: 125px;
}
.banner-benefits {
    display: flex;
    justify-content: center;
    color: #fff;
    justify-content: flex-start;
    position: relative;
}
.banner-benefits-items {
    position: relative;
}
.banner-lines {
    position: absolute;
    right: 8%;
    bottom: 1%;
}
.banner-lines img {
    min-width: 198px;
}
.banner-benefits-items:before {
    position: absolute;
    content: '';
    width: 2px;
    top: 0;
    bottom: 0;
    top: 0;
    left: -10px;
    background-image: linear-gradient( 90deg, rgb(199,211,128) 0%, rgb(230,227,223) 100%);
    box-shadow: 0.5px 0.866px 0px 0px rgba(0, 0, 0, 0.2);
}
.banner-benefits-items:not(:last-child) {
    margin-right: 250px;
}
.banner-benefits-items .banner-benefits-blok:first-child {
    margin-bottom: 50px;
}
.banner-benefits-blok {
    max-width: 325px;
    line-height: 25px;
}
.banner-benefits-blok img {
    transition: .5s ease-in;
}
.banner-benefits-blok:hover img {
    transform: scale(1.08);
}
.main-text-block {
    display: flex;
    margin-top: 90px;
    margin-bottom: 112px;
}
.main-img {
    min-width: 596px;
}
.main-text {
    margin-right: 100px;
    line-height: 30px;
}
.main-text .btn {
    margin-top: 95px;
}
.services {
    margin-bottom: 137px;
    overflow: hidden;
}
.services h2 {
    margin-bottom: 95px;
    position: relative;
}
.services h2::before {
    position: absolute;
    content: '';
    right: 0;
    bottom: 0;
    height: 1px;
    width: 90%;
    background-color: #e1ff35;
    left: 20%;
    width: 75%;
}
.services h2::after {
    position: absolute;
    content: '';
    right: 0;
    bottom: -19px;
    height: 1px;
    width: 90%;
    background-color: #e1ff35;
    left: 30%;
    width: 73%;
}
.services-row {
    display: flex;
}
.services p {
    color: #6c6b5f;
    min-height: 80px;
    height: 80px;
    overflow: hidden;
    line-height: 25px;
    text-overflow: ellipsis;
}
.services-item {
    max-width: 50%;
    width: 100%;
    margin-left: 39px;
    position: relative;
}
.services-item:last-child {
    margin-bottom: 0;
}
.services-item h3 {
    transition: .3s ease-in;
}
.services-img:before {
    opacity: 0;
    background-color: transparent;
    transition: 5s ease-in;
}
.blackout {
    position: absolute;
    content: '';
    background-color: #000;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: .3s ease-in;
}
.services-item:hover {
    cursor: pointer;
    h3 {
        color: #afc62c;
    }
    .blackout {
        opacity: .4;
    }
    .btn-light {
        background-color: #cde449;
    }
    .btn-light span:first-child::before,
    .btn-light span:first-child::after,
    .btn-light span:last-child::before,
    .btn-light span:last-child::after {
        background-color: #b1c82e;
    }
}
.services h3 {
    position: relative;
}
.services h3:before {
    position: absolute;
    content: url(../img/material/lines.png);
    transform: translate(-50%, -50%);
    left: -5px;
}
.services-img {
    width: 100%;
    margin-bottom: 30px;
    height: 500px;
    background-size: cover;
    position: relative;
}
.services-row:not(:last-child) {
    margin-bottom: 78px;
}
.services-row:nth-child(2n+1) {
    .services-item {
        width: 50%;
        max-width: 50%;
    }
    .services-img {
        width: 100%;
    }
}
.overlay {
    opacity: 0.149;
    text-shadow: 0.5px 0.866px 0px rgba(0, 0, 0, 0.2);
    background-color: rgb(0, 0, 0);
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 0;
}
.banner-main {
    position: relative;
}
.banner-main::before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(255,255,255,0.2) 100%);
    opacity: 0.702;
      
}
.banner-contacts {
    background-image: url(../img/bg-info.png);
    padding: 0;
}
.banner-contacts-wrapper {
    background-image: url(../img/bg-light.png);
    
    padding-top: 172px;
    padding-bottom: 84px;
}
.banner-contacts-wrapper .section {
    display: flex;
    justify-content: space-between;
}
.banner-contacts-icon {
    max-width: 110px;
    margin-bottom: 20px;
}
.banner-contacts-wrapper .commercial-form {
    margin-left: 0;
}
.banner-contacts-block {
    padding-top: 48px;
    padding-bottom: 44px;
}
.banner-contacts-block .contacts-social p {
    font-size: 20px;
    line-height: 20px;
}
.banner-contacts-block .contacts-item {
    padding-bottom: 15px;
}
.banner-contacts-block .contacts-text {
    font-size: 20px;
    line-height: 20px;
}
.banner-contacts-block .contacts-text p{
 margin: 0;
}
.banner-contacts-block .contacts-phone .contacts-text {
    font-size: 30px;
}
.banner-contacts-block .contacts-phone .contacts-text a {
    padding-bottom: 20px;
}
.banner-callback-block .btn-light {
    margin-top: 45px;
}
@media screen and (max-width:1650px) {
    .banner-text {
        right: -5%;
    }
}
@media screen and (max-width:1580px) {
    .banner-video:before, .banner-info:before {
        right: 0;
        bottom: 0;
    }
    .banner-video:after, .banner-info:after {
        display: none;
    }
    .banner-video .banner-text, .banner-info .banner-text {
        right: 10px;
    }
}
@media screen and (max-width:1550px) {
    .banner-contacts-block .contacts-item {
        margin-bottom: 0;
    }
    .banner-lines {
        display: none;
    }
    .banner-text {
        transform: rotate(0);
        right: 10px;
        bottom: 0;
        top: unset;
    }
    .banner-contacts-wrapper .section {
        flex-wrap: wrap;
    }
}
@media screen and (max-width:1490px) {
    .banner-contacts-wrapper {
        background-image: none;
    }
    .banner.banner-contacts {
        max-height: 100%;
        height: 100%;
    }
    .banner-contacts-item {
        margin-bottom: 30px;
    }
    .banner-info-img {
        display: none;
    }
    .banner-info-text {
        margin-left: auto;
        margin-right: auto;
    }
    .banner.banner-video {
        // height: 90vh;
    }
    .banner-info-author {
        bottom: -7%;
    }
    .banner-info:before {
        display: none;
    }
    .banner-main {
        padding-top: 225px;
    }
}
@media screen and (max-width:1199px) {
    .services-row {
        flex-direction: column;
    }
    .services-item {
        margin-left: auto;
        margin-right: auto;
        max-width: 50%;
        width: 100%;
        margin-bottom: 70px;
    }
    .main-img {
        min-width: unset;
        width: 100%;
    }
    
}
@media screen and (max-width:1040px) {
    .banner-contacts-wrapper .section {
        flex-direction: column;
        align-items: center;
    }
    .banner-contacts-wrapper .commercial-block {
        max-width: 100%;
    }
    .banner-contacts-wrapper  .commercial-form {
        width: 100%;
    }
}
@media screen and (max-width:991px) {
    .banner-text::before {
        top: -180%;
        right: 50%;
        left: unset;
    }
    .services-item {
        max-width: 90%;
    }
    .services-row:nth-child(2n+1) .services-item {
        width: 90%;
        max-width: unset;
    }
    .banner-benefits-items:not(:last-child) {
        margin-right: 10%;
    }
    .banner-video .banner-text, .banner-info .banner-text {
        right: 5%;
        bottom: 6px;
        top: 93%;
        transform: rotate(0);
    }
    .banner-video .banner-text::before, .banner-info .banner-text::before {
        left: 22%;
    }
    .banner-video .banner-text::before {
        top: -165%;
    }
    .banner-video:before, .banner-info:before {
        bottom: 2px;
        display: none;
    }
    .banner-contacts-icon {
        max-width: 82px;
    }
    .banner-contacts-wrapper .commercial-form {
        margin-top: 15px;
    }
    .banner-contacts-wrapper {
        padding-top: 120px;
    }
}
@media screen and (max-width:900px) {
    .banner-main {
        padding-top: 140px;
    }
    
    .banner h1 {
        padding-bottom: 50px!important;
    }
}
.banner {
    max-height: 100%;
    height: 100vh;
}
@media screen and (max-width:768px) {
    .banner {
        max-height: 100%;
        height: 100vh;
    }
    .main-text-block {
        flex-direction: column-reverse;
    }
    .main-img img {
        margin-left: auto;
        margin-right: auto;
    }
    .banner-benefits {
        flex-wrap: wrap;
    }
    .banner-benefits-items {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }
    .banner-benefits-items:not(:last-child) {
        margin-right: 0;
    }
    .banner-benefits-blok {
        max-width: 250px;
    }
    .banner-info {
        margin-top: 0;
    }
    .banner.banner-video {
        height: 100vh;
    }
    .banner-info-text {
        border: 0px;
    }
    .banner-info-text::after, .banner-info-text::before {
        display: none;
    }
    .banner-info-text {
        max-height: 100%;
    }
}
@media screen and (max-width:767px) {
    .banner {
        max-height: 100%;
        height: 100%;
    }
}
@media screen and (max-width:550px) {
    
    .banner-info-text {
        max-width: 100%;
        padding: 25px;
    }
    .banner-benefits {
        max-width: 250px;
        margin-left: auto;
        margin-right: auto;
    }
    .banner-benefits-items {
        flex-direction: column;
        align-items: baseline;
    }
    .services-img {
        height: 350px;
    }
    
    .banner-info-text {
        max-height: 100%;
    }
    .banner {
        padding-top: 220px;
        padding-bottom: 150px;
    }
}
@media screen and (max-width:400px) {
    .banner-info-author:before {
        top: 66%;
    }
}
@font-face {
  font-family: 'Kelson Sans';
  src: local('Kelson Sans Light RU'), local('KelsonSans-LightRU'), url('../fonts/Kelson/kelsonsanslightru.woff2') format('woff2'), url('../fonts/Kelson/kelsonsanslightru.woff') format('woff'), url('../fonts/Kelson/kelsonsanslightru.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Kelson Sans';
  src: local('Kelson Sans Regular RU'), local('KelsonSans-RegularRU'), url('../fonts/Kelson/kelsonsansregularru.woff2') format('woff2'), url('../fonts/Kelson/kelsonsansregularru.woff') format('woff'), url('../fonts/Kelson/kelsonsansregularru.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Kelson Sans';
  src: local('Kelson Sans Bold RU'), local('KelsonSans-BoldRU'), url('../fonts/Kelson/kelsonsansboldru.woff2') format('woff2'), url('../fonts/Kelson/kelsonsansboldru.woff') format('woff'), url('../fonts/Kelson/kelsonsansboldru.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  src: url('HarmoniaSansProCyr-Black.eot');
  src: local('Harmonia Sans Pro Cyr Black'), local('HarmoniaSansProCyr-Black'), url('../fonts/Harmonia/HarmoniaSansProCyr-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-Black.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-Black.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  src: url('HarmoniaSansProCyr-BoldIt.eot');
  src: local('Harmonia Sans Pro Cyr Bold Italic'), local('HarmoniaSansProCyr-BoldIt'), url('../fonts/Harmonia/HarmoniaSansProCyr-BoldIt.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-BoldIt.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-BoldIt.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-BoldIt.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr Semi Bd';
  src: url('HarmoniaSansProCyr-SemiBdIt.eot');
  src: local('Harmonia Sans Pro Cyr Semi Bold Italic'), local('HarmoniaSansProCyr-SemiBdIt'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdIt.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdIt.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdIt.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdIt.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  src: url('HarmoniaSansProCyr-Light.eot');
  src: local('Harmonia Sans Pro Cyr Light'), local('HarmoniaSansProCyr-Light'), url('../fonts/Harmonia/HarmoniaSansProCyr-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-Light.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-Light.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  src: url('HarmoniaSansProCyr-BlackIt.eot');
  src: local('Harmonia Sans Pro Cyr Black Italic'), local('HarmoniaSansProCyr-BlackIt'), url('../fonts/Harmonia/HarmoniaSansProCyr-BlackIt.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-BlackIt.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-BlackIt.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-BlackIt.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  src: url('HarmoniaSansProCyr-Bold.eot');
  src: local('Harmonia Sans Pro Cyr Bold'), local('HarmoniaSansProCyr-Bold'), url('../fonts/Harmonia/HarmoniaSansProCyr-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-Bold.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-Bold.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  src: url('HarmoniaSansProCyr-LightIt.eot');
  src: local('Harmonia Sans Pro Cyr Light Italic'), local('HarmoniaSansProCyr-LightIt'), url('../fonts/Harmonia/HarmoniaSansProCyr-LightIt.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-LightIt.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-LightIt.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-LightIt.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr Cond';
  src: url('HarmoniaSansProCyr-BoldCond.eot');
  src: local('Harmonia Sans Pro Cyr Bold Cond'), local('HarmoniaSansProCyr-BoldCond'), url('../fonts/Harmonia/HarmoniaSansProCyr-BoldCond.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-BoldCond.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-BoldCond.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-BoldCond.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  src: url('HarmoniaSansProCyr-Italic.eot');
  src: local('Harmonia Sans Pro Cyr Italic'), local('HarmoniaSansProCyr-Italic'), url('../fonts/Harmonia/HarmoniaSansProCyr-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-Italic.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-Italic.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr Semi Bd';
  src: url('HarmoniaSansProCyr-SemiBd.eot');
  src: local('Harmonia Sans Pro Cyr Semi Bd'), local('HarmoniaSansProCyr-SemiBd'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBd.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBd.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBd.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBd.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr';
  src: url('HarmoniaSansProCyr-Regular.eot');
  src: local('Harmonia Sans Pro Cyr'), local('HarmoniaSansProCyr-Regular'), url('../fonts/Harmonia/HarmoniaSansProCyr-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-Regular.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-Regular.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr SemiBdCn';
  src: url('HarmoniaSansProCyr-SemiBdCn.eot');
  src: local('Harmonia Sans Pro Cyr Semi Bold Cond'), local('HarmoniaSansProCyr-SemiBdCn'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdCn.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdCn.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdCn.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdCn.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Harmonia Sans Pro Cyr Cond';
  src: url('HarmoniaSansProCyr-Cond.eot');
  src: local('Harmonia Sans Pro Cyr Cond'), local('HarmoniaSansProCyr-Cond'), url('../fonts/Harmonia/HarmoniaSansProCyr-Cond.eot?#iefix') format('embedded-opentype'), url('../fonts/Harmonia/HarmoniaSansProCyr-Cond.woff2') format('woff2'), url('../fonts/Harmonia/HarmoniaSansProCyr-Cond.woff') format('woff'), url('../fonts/Harmonia/HarmoniaSansProCyr-Cond.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html,
body {
  font-family: 'Harmonia Sans Pro Cyr';
  font-size: 18px;
  font-weight: 400;
  padding: 0;
  margin: 0;
  min-height: 100%;
  position: relative;
  color: #161d1f;
  line-height: 1;
}
/* fix for top margin collapsing and vertical scroll prevent for body tag, when body height = 100% */
body::before {
  content: "";
  display: block;
  width: 1px;
  height: 0px;
  background-color: transparent;
}
input,
textarea {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  resize: vertical;
}
button,
input {
  outline: none !important;
}
button {
  background-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
  border: none;
  box-shadow: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 400;
  line-height: 1;
}
.page h1 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 3px;
}
h2 {
  font-size: 50px;
  margin-bottom: 56px;
  text-transform: uppercase;
  font-weight: 900;
  letter-spacing: 3px;
}
h3 {
  font-size: 25px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 14px;
}
ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
img {
  display: block;
  max-width: 100%;
}
.fixed {
  position: fixed;
}
a {
  color: #fff;
  text-decoration: none;
}
a:hover {
  color: #cde449;
  text-decoration: none;
}
.section {
  max-width: 1510px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 35px;
  padding-right: 35px;
}
.overflow {
  overflow: hidden;
}
.btn {
  background-color: #cde449;
  font-size: 18px;
  font-weight: 700;
  padding: 23px 52px 19px 49px;
  position: relative;
  white-space: nowrap;
  padding-top: 12px;
  transition: 0.3s ease-in;
}
.btn:hover {
  background-color: #afc62c;
  color: #fff;
  z-index: 5;
}
.btn:hover:after {
  z-index: 1;
}
.btn:after {
  position: absolute;
  background-color: ;
  content: '';
  border: 1px solid #cde449;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.btn:before {
  position: absolute;
  content: '';
  border: 1px solid #777777;
  top: -6px;
  left: -6px;
  right: 6px;
  bottom: 6px;
}
input.btn-light {
  background-color: #fff;
}
.btn-light {
  border: 1px solid #cde449;
  padding-top: 25px;
  padding-bottom: 22px;
  padding-left: 50px;
  padding-right: 50px;
  font-size: 16px;
  font-weight: 700;
  position: relative;
  display: inline-block;
  transition: 0.3s ease-in;
  white-space: nowrap;
}
.blog-card:hover .btn-light:before {
  position: absolute;
  content: url('../img/angle-right.svg');
  left: 10%;
}
.services-item:hover .btn-light:before {
  position: absolute;
  content: url('../img/angle-right.svg');
  left: 10%;
}
.blog-cases:hover .btn-light:before {
  position: absolute;
  content: url('../img/angle-right.svg');
  left: 10%;
}
.btn-light span {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.btn-light span:first-child::before {
  content: '';
  width: 75px;
  height: 2px;
  background-color: #cde449;
  position: absolute;
  left: 0;
  top: 0;
}
.btn-light span:first-child::after {
  content: '';
  width: 75px;
  height: 2px;
  background-color: #cde449;
  position: absolute;
  right: 0;
  bottom: 0;
}
.btn-light span:last-child::before {
  content: '';
  width: 2px;
  height: 40px;
  background-color: #cde449;
  position: absolute;
  left: 0;
  top: 0;
}
.btn-light span:last-child::after {
  content: '';
  width: 2px;
  height: 40px;
  background-color: #cde449;
  position: absolute;
  right: 0;
  bottom: 0;
}
.btn-light:hover {
  background-color: #cde449;
}
.btn-light:hover span:first-child::before,
.btn-light:hover span:first-child::after,
.btn-light:hover span:last-child::before,
.btn-light:hover span:last-child::after {
  background-color: #b1c82e;
}
.breadcrumbs {
  color: #fff;
  background-image: url('../img/footer-bg.jpg');
  margin-bottom: 74px;
  padding-bottom: 115px;
  position: relative;
  overflow: hidden;
}
.breadcrumbs .section:before {
  position: absolute;
  content: url('../img/line.png');
  bottom: 50px;
  left: 0;
}
.breadcrumbs ul {
  display: flex;
  align-items: center;
  padding-bottom: 48px;
  padding-top: 10px;
}
.breadcrumbs ul li {
  padding-right: 40px;
  position: relative;
}
.breadcrumbs ul li a {
  text-decoration: underline;
}
.breadcrumbs ul li svg {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}
.breadcrumbs ul li:last-of-type {
  color: #98978f;
  text-decoration: none;
}
.pagination {
  justify-content: center;
}
.page-link {
  color: #2b272e;
  border: none;
  width: 49px;
  height: 49px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page-item.active .page-link {
  border-radius: 50%;
  background-color: #cde449;
  color: #2b272e;
  padding: 0;
  margin-right: 5px;
  margin-left: 5px;
}
.page-link:hover {
  border-radius: 50%;
  background-color: #cde449;
  color: #2b272e;
  border: none;
  outline: none!important;
}
.page-link:focus {
  box-shadow: none;
}
.page-item:first-child .page-link {
  border-radius: 50%;
}
.page-item:last-child .page-link {
  border-radius: 50%;
}
.close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  cursor: pointer;
}
.close:hover {
  opacity: 1;
}
.close:before,
.close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #fff;
}
.close:before {
  transform: rotate(45deg);
}
.close:after {
  transform: rotate(-45deg);
}
video {
  position: fixed;
  z-index: -1;
  min-width: 100%;
  min-height: 100%;
  overflow: hidden;
}
.owl-carousel .owl-item img {
  width: auto;
}
@media screen and (max-width: 520px) {
  h2 {
    font-size: 25px;
    margin-bottom: 26px;
  }
  .section {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media screen and (max-width: 450px) {
  .page h1 {
    font-size: 35px;
    line-height: 50px;
  }
  .breadcrumbs ul {
    flex-wrap: wrap;
    line-height: 30px;
  }
  .page-link {
    width: 40px;
    height: 40px;
  }
  .footer-copyright {
    text-align: center;
  }
}
@media (min-width: 576px) {
  .custom .container {
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .custom .container {
    max-width: 950px;
  }
}
@media (min-width: 992px) {
  .custom .container {
    max-width: 1050px;
  }
}
@media (min-width: 1200px) {
  .custom .container {
    max-width: 1720px;
  }
}
@media (min-width: 992px) and (max-width: 1420px) {
  .custom .col-xl-4 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
/*main page*/
.banner {
  padding-top: 335px;
  padding-bottom: 150px;
  background-size: cover;
  position: relative;
  max-height: 1062px;
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
}
.banner-text {
  position: absolute;
  color: #fff;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 60px;
  letter-spacing: 2.6px;
  right: 0;
  top: 45%;
  transform: rotate(-90deg);
  cursor: pointer;
}
.banner-video .banner-text,
.banner-info .banner-text {
  right: 4%;
}
.banner-video .banner-text::before,
.banner-info .banner-text::before {
  left: -85%;
}
.banner-video:before,
.banner-info:before {
  position: absolute;
  content: '';
  left: 0;
  right: 7%;
  bottom: 15%;
  height: 1px;
  background-color: #afc62c;
}
.banner-video:after,
.banner-info:after {
  position: absolute;
  content: '';
  right: -7%;
  bottom: 15%;
  width: 1px;
  background-color: transparent;
  height: 50%;
  width: 14%;
  border-top: 1px solid #afc62c;
  border-left: 1px solid #afc62c;
}
.banner-text::before {
  position: absolute;
  content: '';
  width: 1px;
  height: 236px;
  background-color: #cde449;
  transform: rotate(-90deg);
  left: -50%;
  top: -145%;
}
.banner-video {
  height: 100vh;
}
.banner-info {
  background-image: url(../img/bg-info.png);
  position: relative;
  display: flex;
  padding-top: 225px;
  align-items: center;
  padding-bottom: 270px;
}
.banner-info-header {
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  color: #cde449;
  font-weight: 900;
  margin-bottom: 33px;
}
.banner-info p {
  line-height: 30px;
}
.banner-info-text {
  max-width: 729px;
  color: #fff;
  padding: 40px;
  max-height: 610px;
  position: relative;
  background-color: #fff;
  background-image: url(../img/bg-info.png);
}
.banner-info-text::before {
  position: absolute;
  content: url('../img/dialog.png');
  top: 0;
  left: 0;
}
.banner-info-author {
  color: #afc62c;
  font-size: 25px;
  line-height: 25px;
  text-transform: uppercase;
  position: absolute;
  bottom: -6%;
  left: 11%;
}
.banner-info-author:before {
  position: absolute;
  content: url(../img/small-logo.png);
  top: 50%;
  bottom: 0;
  left: -11%;
  right: 0;
}
.banner-info-author p {
  margin-bottom: 0;
}
.banner-info-img {
  z-index: 5;
}
.banner-info-img img {
  width: 649px!important;
  width: 600px!important;
}
.overlay {
  position: absolute;
  content: '';
  background: linear-gradient(0deg, #000000 0%, #ffffff 100%);
  opacity: 0.3;
  left: 0;
  bottom: 0;
  right: 0;
}
.banner h1 {
  font-size: 65px;
  font-family: "Kelson Sans";
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 6px 4px #cde449;
  color: transparent;
  -webkit-text-stroke: 2px #504e3b;
  padding-bottom: 125px;
}
.banner-benefits {
  display: flex;
  justify-content: center;
  color: #fff;
  justify-content: flex-start;
  position: relative;
}
.banner-benefits-items {
  position: relative;
}
.banner-lines {
  position: absolute;
  right: 8%;
  bottom: 1%;
}
.banner-lines img {
  min-width: 198px;
}
.banner-benefits-items:before {
  position: absolute;
  content: '';
  width: 2px;
  bottom: 0;
  top: 0;
  left: -10px;
  background-image: linear-gradient(90deg, #c7d380 0%, #e6e3df 100%);
  box-shadow: 0.5px 0.866px 0px 0px rgba(0, 0, 0, 0.2);
}
.banner-benefits-items:not(:last-child) {
  margin-right: 250px;
}
.banner-benefits-items .banner-benefits-blok:first-child {
  margin-bottom: 50px;
}
.banner-benefits-blok {
  max-width: 325px;
  line-height: 25px;
}
.banner-benefits-blok img {
  transition: 0.5s ease-in;
}
.banner-benefits-blok:hover img {
  transform: scale(1.08);
}
.main-text-block {
  display: flex;
  margin-top: 90px;
  margin-bottom: 112px;
}
.main-img {
  min-width: 596px;
}
.main-text {
  margin-right: 100px;
  line-height: 30px;
}
.main-text .btn {
  margin-top: 95px;
}
.services {
  margin-bottom: 137px;
  overflow: hidden;
}
.services h2 {
  margin-bottom: 95px;
  position: relative;
}
.services h2::before {
  position: absolute;
  content: '';
  right: 0;
  bottom: 0;
  height: 1px;
  width: 90%;
  background-color: #e1ff35;
  left: 20%;
  width: 75%;
}
.services h2::after {
  position: absolute;
  content: '';
  right: 0;
  bottom: -19px;
  height: 1px;
  width: 90%;
  background-color: #e1ff35;
  left: 30%;
  width: 73%;
}
.services-row {
  display: flex;
}
.services p {
  color: #6c6b5f;
  min-height: 80px;
  height: 80px;
  overflow: hidden;
  line-height: 25px;
  text-overflow: ellipsis;
}
.services-item {
  max-width: 50%;
  width: 100%;
  margin-left: 39px;
  position: relative;
}
.services-item:last-child {
  margin-bottom: 0;
}
.services-item h3 {
  transition: 0.3s ease-in;
}
.services-img:before {
  opacity: 0;
  background-color: transparent;
  transition: 5s ease-in;
}
.blackout {
  position: absolute;
  content: '';
  background-color: #000;
  opacity: 0;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s ease-in;
}
.services-item:hover {
  cursor: pointer;
}
.services-item:hover h3 {
  color: #afc62c;
}
.services-item:hover .blackout {
  opacity: 0.4;
}
.services-item:hover .btn-light {
  background-color: #cde449;
}
.services-item:hover .btn-light span:first-child::before,
.services-item:hover .btn-light span:first-child::after,
.services-item:hover .btn-light span:last-child::before,
.services-item:hover .btn-light span:last-child::after {
  background-color: #b1c82e;
}
.services h3 {
  position: relative;
}
.services h3:before {
  position: absolute;
  content: url(../img/material/lines.png);
  transform: translate(-50%, -50%);
  left: -5px;
}
.services-img {
  width: 100%;
  margin-bottom: 30px;
  height: 500px;
  background-size: cover;
  position: relative;
}
.services-row:not(:last-child) {
  margin-bottom: 78px;
}
.services-row:nth-child(2n+1) .services-item {
  width: 50%;
  max-width: 50%;
}
.services-row:nth-child(2n+1) .services-img {
  width: 100%;
}
.overlay {
  opacity: 0.149;
  text-shadow: 0.5px 0.866px 0px rgba(0, 0, 0, 0.2);
  background-color: #000000;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}
.banner-main {
  position: relative;
}
.banner-main::before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7) 0%, rgba(255, 255, 255, 0.2) 100%);
  opacity: 0.702;
}
.banner-contacts {
  background-image: url(../img/bg-info.png);
  padding: 0;
}
.banner-contacts-wrapper {
  background-image: url(../img/bg-light.png);
  padding-top: 172px;
  padding-bottom: 84px;
}
.banner-contacts-wrapper .section {
  display: flex;
  justify-content: space-between;
}
.banner-contacts-icon {
  max-width: 110px;
  margin-bottom: 20px;
}
.banner-contacts-wrapper .commercial-form {
  margin-left: 0;
}
.banner-contacts-block {
  padding-top: 48px;
  padding-bottom: 44px;
}
.banner-contacts-block .contacts-social p {
  font-size: 20px;
  line-height: 20px;
}
.banner-contacts-block .contacts-item {
  padding-bottom: 15px;
}
.banner-contacts-block .contacts-text {
  font-size: 20px;
  line-height: 20px;
}
.banner-contacts-block .contacts-text p {
  margin: 0;
}
.banner-contacts-block .contacts-phone .contacts-text {
  font-size: 30px;
}
.banner-contacts-block .contacts-phone .contacts-text a {
  padding-bottom: 20px;
}
.banner-callback-block .btn-light {
  margin-top: 45px;
}
@media screen and (max-width: 1650px) {
  .banner-text {
    right: -5%;
  }
}
@media screen and (max-width: 1580px) {
  .banner-video:before,
  .banner-info:before {
    right: 0;
    bottom: 0;
  }
  .banner-video:after,
  .banner-info:after {
    display: none;
  }
  .banner-video .banner-text,
  .banner-info .banner-text {
    right: 10px;
  }
}
@media screen and (max-width: 1550px) {
  .banner-contacts-block .contacts-item {
    margin-bottom: 0;
  }
  .banner-lines {
    display: none;
  }
  .banner-text {
    transform: rotate(0);
    right: 10px;
    bottom: 0;
    top: unset;
  }
  .banner-contacts-wrapper .section {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1490px) {
  .banner-contacts-wrapper {
    background-image: none;
  }
  .banner.banner-contacts {
    max-height: 100%;
    height: 100%;
  }
  .banner-contacts-item {
    margin-bottom: 30px;
  }
  .banner-info-img {
    display: none;
  }
  .banner-info-text {
    margin-left: auto;
    margin-right: auto;
  }
  .banner-info-author {
    bottom: -7%;
  }
  .banner-info:before {
    display: none;
  }
  .banner-main {
    padding-top: 225px;
  }
}
@media screen and (max-width: 1199px) {
  .services-row {
    flex-direction: column;
  }
  .services-item {
    margin-left: auto;
    margin-right: auto;
    max-width: 50%;
    width: 100%;
    margin-bottom: 70px;
  }
  .main-img {
    min-width: unset;
    width: 100%;
  }
}
@media screen and (max-width: 1040px) {
  .banner-contacts-wrapper .section {
    flex-direction: column;
    align-items: center;
  }
  .banner-contacts-wrapper .commercial-block {
    max-width: 100%;
  }
  .banner-contacts-wrapper .commercial-form {
    width: 100%;
  }
}
@media screen and (max-width: 991px) {
  .banner-text::before {
    top: -180%;
    right: 50%;
    left: unset;
  }
  .services-item {
    max-width: 90%;
  }
  .services-row:nth-child(2n+1) .services-item {
    width: 90%;
    max-width: unset;
  }
  .banner-benefits-items:not(:last-child) {
    margin-right: 10%;
  }
  .banner-video .banner-text,
  .banner-info .banner-text {
    right: 5%;
    bottom: 6px;
    top: 93%;
    transform: rotate(0);
  }
  .banner-video .banner-text::before,
  .banner-info .banner-text::before {
    left: 22%;
  }
  .banner-video .banner-text::before {
    top: -165%;
  }
  .banner-video:before,
  .banner-info:before {
    bottom: 2px;
    display: none;
  }
  .banner-contacts-icon {
    max-width: 82px;
  }
  .banner-contacts-wrapper .commercial-form {
    margin-top: 15px;
  }
  .banner-contacts-wrapper {
    padding-top: 120px;
  }
}
@media screen and (max-width: 900px) {
  .banner-main {
    padding-top: 140px;
  }
  .banner h1 {
    padding-bottom: 50px!important;
  }
}
.banner {
  max-height: 100%;
  height: 100vh;
}
@media screen and (max-width: 768px) {
  .banner {
    max-height: 100%;
    height: 100vh;
  }
  .main-text-block {
    flex-direction: column-reverse;
  }
  .main-img img {
    margin-left: auto;
    margin-right: auto;
  }
  .banner-benefits {
    flex-wrap: wrap;
  }
  .banner-benefits-items {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .banner-benefits-items:not(:last-child) {
    margin-right: 0;
  }
  .banner-benefits-blok {
    max-width: 250px;
  }
  .banner-info {
    margin-top: 0;
  }
  .banner.banner-video {
    height: 100vh;
  }
  .banner-info-text {
    border: 0px;
  }
  .banner-info-text::after,
  .banner-info-text::before {
    display: none;
  }
  .banner-info-text {
    max-height: 100%;
  }
}
@media screen and (max-width: 767px) {
  .banner {
    max-height: 100%;
    height: 100%;
  }
}
@media screen and (max-width: 550px) {
  .banner-info-text {
    max-width: 100%;
    padding: 25px;
  }
  .banner-benefits {
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
  .banner-benefits-items {
    flex-direction: column;
    align-items: baseline;
  }
  .services-img {
    height: 350px;
  }
  .banner-info-text {
    max-height: 100%;
  }
  .banner {
    padding-top: 220px;
    padding-bottom: 150px;
  }
}
@media screen and (max-width: 400px) {
  .banner-info-author:before {
    top: 66%;
  }
}
/*services page*/
/*services page*/
.img-rotation-1 {
  position: absolute;
  top: -3%;
  left: 50%;
  animation: rotateimg 35s linear infinite;
}
.img-rotation-3 {
  position: absolute;
  top: 68%;
  left: 92%;
  animation: rotateimg 15s linear infinite;
}
.img-rotation-2 {
  position: absolute;
  top: 33%;
  left: 95%;
  animation: rotateimg 20s linear infinite;
}
.img-rotation-4 {
  position: absolute;
  top: 97%;
  left: 50%;
  animation: rotateimg 15s linear infinite;
}
.img-rotation-5 {
  position: absolute;
  top: 84%;
  left: 13%;
  animation: rotateimg 10s linear infinite;
}
.img-rotation-6 {
  position: absolute;
  top: 41%;
  left: -2%;
  animation: rotateimg 10s linear infinite;
}
.rotation-3 {
  position: absolute;
  left: 9%;
  top: 10%;
  animation: rotate 15s linear infinite;
  border-radius: 50%;
  height: 350px;
  width: 350px;
}
.rotation-1 {
  position: absolute;
  left: 4%;
  top: 4%;
  animation: rotate 35s linear infinite;
  border-radius: 50%;
  height: 400px;
  width: 400px;
}
.rotation-2 {
  position: absolute;
  left: 4%;
  top: 4%;
  animation: rotate 20s linear infinite;
  border-radius: 50%;
  height: 400px;
  width: 400px;
}
.rotation-4 {
  position: absolute;
  left: 4%;
  top: 4%;
  animation: rotate 15s linear infinite;
  border-radius: 50%;
  height: 400px;
  width: 400px;
}
.rotation-5 {
  position: absolute;
  left: 4%;
  top: 4%;
  animation: rotate 10s linear infinite;
  border-radius: 50%;
  height: 400px;
  width: 400px;
}
.rotation-6 {
  position: absolute;
  left: 4%;
  top: 4%;
  animation: rotate 10s linear infinite;
  border-radius: 50%;
  height: 400px;
  width: 400px;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rotateimg {
  0% {
    transform: rotate(0);
  }
  25% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  75% {
    transform: rotate(-270deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
.work-wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.work-wrapper:after {
  position: absolute;
  content: url('../img/material/lines-green.png');
  bottom: -17%;
  left: -2%;
}
.work-img {
  position: relative;
  height: 100%;
}
.mouse-parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
}
.work-img p {
  position: absolute;
  font-size: 60px;
  line-height: 60px;
  color: #afc62c;
  transform: rotate(-90deg) translate(-50%, -250%);
  white-space: nowrap;
  top: 0;
  left: 0;
  font-weight: 900;
}
.work-img:before {
  position: absolute;
  content: url('../img/material/lines-black.png');
  bottom: 17%;
  transform: translateY(150%);
  left: 75px;
}
.work-list-title {
  color: #afc62c;
  font-size: 40px;
  line-height: 60px;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  font-weight: 600;
  padding-left: 35px;
  position: relative;
}
.work-list-title::before {
  position: absolute;
  content: '';
  left: 0;
  width: 24px;
  height: 1px;
  background-color: #afc62c;
  top: 50%;
}
.work-list-text {
  background-color: #f5f6ec;
  font-size: 20px;
  line-height: 30px;
  padding: 35px 40px;
  position: relative;
  z-index: 5;
}
.work li:last-child .work-list-text::before {
  display: none;
}
.work-list-text::before {
  position: absolute;
  content: url('../img/arrow-down.png');
  transform: translateX(-100%);
  left: -65px;
  bottom: -10px;
}
.work ol {
  counter-reset: myCounter;
  max-width: 950px;
  max-width: 800px;
}
.work li {
  list-style: none;
  position: relative;
  margin-bottom: 60px;
}
.work li:before {
  position: absolute;
  counter-increment: myCounter;
  content: counter(myCounter);
  color: #2b272e;
  font-size: 85px;
  line-height: 60px;
  height: 70px;
  z-index: 5;
  background: #fff;
  transform: translate(-100%, 48%);
  left: -51px;
  z-index: 10;
}
.work li:after {
  position: absolute;
  content: '';
  top: 0;
  transform: translate(-100%, 14%);
  left: -30px;
  width: 90px;
  height: 90px;
  border: 1px solid #2b272e;
  border-radius: 50%;
  background-color: #fff;
  z-index: 5;
}
.commercial {
  padding-bottom: 110px;
  background-color: #e9eae1;
  padding-top: 70px;
  position: relative;
  overflow: hidden;
}
.commercial:before {
  position: absolute;
  content: url('../img/material/lines2.png');
  right: 0;
  bottom: 132px;
  right: -13%;
}
.commercial:after {
  position: absolute;
  content: url('../img/material/lines2.png');
  right: 0;
  bottom: 110px;
  right: -18%;
}
.commercial h3 {
  font-size: 40px;
  line-height: 60px;
  font-weight: 900;
  letter-spacing: 2.4px;
}
.commercial-contact {
  font-size: 25px;
  color: #2b272e;
  line-height: 30px;
  display: flex;
  font-weight: 700;
}
.commercial-description {
  margin-bottom: 27px;
  line-height: 30px;
}
.commercial-contact img {
  margin-right: 22px;
}
.commercial-form {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  z-index: 5;
  position: relative;
  margin-left: 170px;
  width: 465px;
}
.commercial-form::before {
  position: absolute;
  content: '';
  border: 20px solid transparent;
  border-right: 20px solid #cde449;
  border-bottom: 20px solid #cde449;
  bottom: 0;
  right: 0;
}
.commercial-wrapper {
  display: flex;
}
.commercial-block {
  max-width: 806px;
}
.commercial-form-header {
  padding: 33px 40px 27px 40px;
  font-size: 30px;
  color: #fff;
  font-weight: 900;
  line-height: 40px;
  background-color: #a6b165;
  text-transform: uppercase;
  letter-spacing: 3px;
}
.commercial-form-input {
  border: none;
  border-bottom: 1px solid #272622;
  height: 30px;
  margin-top: 35px;
  margin-left: 35px;
  margin-right: 43px;
}
.commercial-form-input::placeholder {
  color: #272622;
  font-size: 18px;
  font-weight: 600;
  line-height: 60px;
  padding: 8px 25px;
}
.commercial-form textarea {
  border: 1px solid #272622;
  padding: 11px 23px;
  margin-top: 55px;
  margin-bottom: 45px;
  height: 158px;
  resize: none;
  margin-left: 35px;
  margin-right: 43px;
}
.commercial-form textarea::placeholder {
  color: #272622;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
}
.commercial-list-header {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #afc62c;
  text-transform: uppercase;
  padding-top: 70px;
  padding-bottom: 35px;
  position: relative;
}
.commercial-list-header:before {
  position: absolute;
  content: url('../img/material/lines2.png');
  top: 0;
  left: -22%;
}
.commercial-list-header:after {
  position: absolute;
  content: url('../img/material/lines2.png');
  top: 20px;
  left: 24px;
}
.commercial-form .btn-light {
  margin-left: 35px;
  margin-right: 43px;
  max-width: 250px;
  margin-bottom: 72px;
}
.commercial-list ul {
  position: relative;
}
.commercial-list ul:before {
  position: absolute;
  content: '';
  top: 0;
  bottom: 0;
  left: 0;
  width: 30px;
  background-image: url('../img/material/pattern_lines.png');
}
.commercial-list li {
  font-size: 22px;
  line-height: 30px;
  font-weight: 600;
  padding-bottom: 45px;
  padding-left: 75px;
  position: relative;
}
.commercial-list li:last-child {
  padding-bottom: 15px;
}
.commercial-list li:before {
  position: absolute;
  content: url('../img/arrow-right-green.svg');
  left: 20px;
}
.main-img {
  position: relative;
  height: 100%;
}
.main-img > img {
  min-width: 380px;
  width: 100%;
}
.pulse {
  position: absolute;
  left: 10%;
  bottom: 0;
  top: 4%;
  animation: pulse 2.5s infinite;
}
@keyframes pulse {
  0% {
    transform: scale(0.7);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(0.7);
  }
}
@media screen and (max-width: 1460px) {
  .work-wrapper {
    flex-direction: column;
  }
  .work ol {
    margin-left: auto;
    margin-right: auto;
    max-width: 70%;
  }
  .work-img:before {
    top: -50%;
    bottom: unset;
    left: 350px;
  }
  .work-img p {
    transform: unset;
    top: 30%;
  }
}
@media screen and (max-width: 1199px) {
  .pulse {
    position: absolute;
    left: 10%;
    bottom: 0;
    top: 5%;
  }
}
@media screen and (max-width: 1020px) {
  .commercial-wrapper {
    flex-direction: column;
  }
  .commercial-form {
    width: 80%;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 767px) {
  .main-text {
    margin-right: 0;
  }
  .main-img {
    display: none;
  }
  .work-img {
    display: none;
  }
}
@media screen and (max-width: 574px) {
  .commercial-form {
    width: 100%;
  }
  .work-img p {
    white-space: unset;
  }
  .work ol {
    max-width: 100%;
    padding-left: 0;
  }
  .work li {
    margin-top: 180px;
  }
  .work li:before {
    transform: none;
    left: 22px;
    top: -26%;
    height: 75px;
  }
  .work-list-title {
    padding-left: 0;
    padding-top: 10px;
  }
  .work li:after {
    transform: none;
    left: 0;
    top: -33%;
  }
  .work-list-title::before {
    display: none;
  }
}
@media screen and (max-width: 420px) {
  .work-img:before {
    top: -60%;
    right: -30px;
  }
  .work li:before {
    left: 22px;
    top: -28%;
    height: 75px;
  }
  .work-list-title {
    font-size: 30px;
    line-height: 40px;
  }
  .commercial-form-input {
    margin-left: 15px;
    margin-right: 15px;
  }
  .commercial-form textarea {
    margin-left: 15px;
    margin-right: 15px;
  }
  .commercial-form .btn-light {
    margin-left: 15px;
    margin-right: 15px;
  }
  .commercial h3 {
    font-size: 30px;
    line-height: 40px;
  }
}
/*blog page*/
.blog-page {
  margin-bottom: 110px;
}
.blog-card {
  margin-bottom: 100px;
  cursor: pointer;
}
.blog-card:hover .blog-card-description {
  color: #fff;
  background-color: transparent;
}
.blog-card:hover .blog-card-subtitle {
  color: #fff;
}
.blog-card:hover .blackout {
  opacity: 0.4;
}
.blog-card:hover .btn-light {
  background-color: #cde449;
}
.blog-card:hover .btn-light span:first-child::before,
.blog-card:hover .btn-light span:first-child::after,
.blog-card:hover .btn-light span:last-child::before,
.blog-card:hover .btn-light span:last-child::after {
  background-color: #b1c82e;
}
.blog-card:hover .blog-card-description::before {
  position: absolute;
  content: url('../img/material/lines2.png');
  top: -5%;
  right: 0;
  left: 35%;
}
.blog-card:hover .blog-card-description::after {
  position: absolute;
  content: url('../img/material/lines2.png');
  left: 0;
  top: -10%;
  right: 30%;
}
.blog-card-wrapper {
  position: relative;
  margin-bottom: 16px;
  height: 500px;
  overflow: hidden;
}
.blog-card-img {
  height: 100%;
  background-size: cover;
}
.blog-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.blog-card-description {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(205, 228, 73, 0.7);
  height: 140px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-left: 27px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.blog-card-title {
  font-size: 25px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
  font-family: 'Harmonia Sans Pro Cyr Semi Bd';
  padding-bottom: 17px;
}
.blog-card-subtitle {
  font-family: 'Harmonia Sans Pro Cyr';
  line-height: 25px;
  color: #6c6b5f;
}
.blog-card .btn-light {
  width: 250px;
  text-align: center;
}
@media screen and (max-width: 576px) {
  .blog-card-description {
    height: auto;
  }
}
/*single blog*/
.blog-single {
  overflow: hidden;
  margin-top: -24px;
}
.blog-single h3 {
  font-size: 30px;
  line-height: 45px;
  color: #afc62c;
  font-weight: 900;
  letter-spacing: 3px;
}
.blog-single-wrapper {
  display: flex;
  line-height: 30px;
  margin-bottom: 50px;
  align-items: center;
}
.blog-single-wrapper > .blog-single-block {
  margin-right: 30px;
}
.blog-single-wrapper > .blog-single-img {
  margin-right: 30px;
}
.blog-single-img {
  min-width: 390px;
  height: 100%;
  background-color: #f5f6ec;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.blog-single-block .commercial-list li {
  font-size: 16px;
  padding-bottom: 17px;
}
.blog-date {
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 50px;
  position: relative;
  color: #6c6b5f;
  display: flex;
  align-items: center;
  height: 54px;
}
.blog-date-bg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -5px;
  z-index: -1;
}
.blog-date-bg img {
  height: 54px;
  width: 17px;
}
.blog-single .blog-page {
  position: relative;
  margin-bottom: 0;
}
.blog-single .blog-page h2 {
  margin-top: 140px;
  position: relative;
}
.blog-single .blog-page h2::before {
  position: absolute;
  content: url('../img/material/lines2.png');
  top: -140px;
  left: 0;
}
.blog-single .blog-page h2::after {
  position: absolute;
  content: url('../img/material/lines2.png');
  top: -120px;
  left: 170px;
}
@media screen and (max-width: 991px) {
  .blog-single-wrapper:nth-child(2n) {
    flex-direction: column;
  }
  .blog-single-wrapper:nth-child(2n+1) {
    flex-direction: column-reverse;
  }
  .blog-single-img {
    margin-bottom: 25px;
  }
  .blog-single-wrapper > .blog-single-img {
    margin-right: 0;
  }
  .blog-single-wrapper > .blog-single-block {
    margin-right: 0;
  }
  .blog-single-img {
    min-width: auto;
  }
  .blog-single-img img {
    max-width: 90%;
  }
}
/*photo page*/
.photo-card {
  padding-bottom: 85px;
  overflow: hidden;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
}
.photo-card-img {
  background-size: auto;
  position: relative;
  background-repeat: no-repeat;
  display: block;
}
.photo-card-wrapper {
  position: relative;
  cursor: pointer;
  height: 100%;
}
.photo-card-lines {
  height: 100%;
  position: absolute;
  right: 0;
}
.photo-card-img:before {
  position: absolute;
  content: url('../img/material/lines2.png');
  bottom: -7px;
  right: 32%;
}
.photo-card-img:after {
  position: absolute;
  content: url('../img/material/lines2.png');
  bottom: -12px;
  left: 32%;
}
.photo-card-wrapper:hover .photo-card-title {
  color: #afc62c;
}
.photo-card-wrapper:hover .blackout {
  opacity: 0.4;
}
.photo-card-wrapper:hover .blackout:before {
  position: absolute;
  content: url('../img/material/lines2.png');
  bottom: 22px;
  right: 32%;
}
.photo-card-wrapper:hover .blackout:after {
  position: absolute;
  content: url('../img/material/lines2.png');
  bottom: 16px;
  left: 32%;
}
.photo-card-title {
  font-family: 'Harmonia Sans Pro Cyr Semi Bd';
  font-size: 25px;
  line-height: 30px;
  text-transform: uppercase;
  font-weight: 600;
  padding-left: 65px;
  position: relative;
  margin-bottom: 19px;
}
.photo-card-title:before {
  position: absolute;
  content: '';
  width: 43px;
  height: 1px;
  background-color: #afc62c;
  left: 0;
  top: 50%;
}
@media screen and (max-width: 991px) {
  .photo-card {
    padding-bottom: 50px;
  }
}
/*about page*/
.about-page p {
  font-weight: 600;
  font-size: 24px;
  line-height: 35px;
}
.about-page h2 {
  margin-top: 110px;
}
.benefits-item {
  margin-right: 25px;
  margin-bottom: 30px;
}
.benefits-item-img {
  width: 92px;
  min-width: 92px;
  margin-right: 25px;
}
.benefits-item-wrpper {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
.benefits-item-title {
  font-size: 30px;
  line-height: 40px;
  font-weight: 600;
  color: #afc62c;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.benefits-item-description {
  position: relative;
  padding: 45px 10px 30px 18px;
  background-color: #f5f6ec;
  color: #6c6b5f;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  max-width: 335px;
}
.benefits-item-description:before {
  position: absolute;
  content: '';
  width: 61px;
  height: 1px;
  background-color: #afc62c;
  top: 25px;
}
.benefits-block {
  display: flex;
  justify-content: space-between;
}
.partners-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.partners-item-img {
  border: 10px solid #dddbd1;
  width: 310px;
  height: 138px;
  margin-right: 25px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
}
.partners-item-img img {
  height: auto;
  width: auto;
  margin-left: auto;
  margin-right: auto;
}
.partners-block {
  margin-bottom: 135px;
  display: flex;
  justify-content: center;
}
.partners-block .partners-item:first-child {
  height: 340px;
}
.partners-block .partners-item:first-child .partners-item-img {
  height: 100%;
}
.partners-block .partners-item:first-child .partners-item-img img {
  height: 240px;
  width: auto;
}
@media screen and (max-width: 1720px) {
  .partners-block {
    flex-wrap: wrap;
  }
  .partners-block .partners-item:last-child {
    flex-direction: row;
  }
}
@media screen and (max-width: 1530px) {
  .benefits-item {
    width: 33.3%;
  }
  .benefits-block {
    justify-content: center;
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 1250px) {
  .benefits-block {
    justify-content: space-around;
  }
}
@media screen and (max-width: 1024px) {
  .benefits-item {
    width: 40%;
  }
}
@media screen and (max-width: 930px) {
  .benefits-item {
    width: 51%;
  }
}
@media screen and (max-width: 767px) {
  .benefits-block {
    justify-content: flex-start;
  }
  .partners-block {
    margin-bottom: 50px;
  }
  .about-page h2 {
    margin-top: 50px;
  }
  .partners-item-img {
    margin-right: 5px;
  }
}
@media screen and (max-width: 699px) {
  .partners-block .partners-item:last-child {
    flex-direction: column;
  }
}
@media screen and (max-width: 540px) {
  .partners-item-img {
    margin-right: 0;
  }
  .benefits-item-img {
    width: 80px;
    min-width: 80px;
    margin-right: 20px;
  }
  .benefits-item {
    width: 100%;
  }
  .benefits-item-title {
    font-size: 19px;
    line-height: 30px;
    font-weight: 600;
  }
}
/*contacts page*/
.contacts-page {
  margin-bottom: 27px;
}
.contacts-page .section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contacts-item {
  position: relative;
  max-width: 350px;
  margin-left: 15px;
}
.contacts-address {
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
}
.contacts-phone {
  display: flex;
  align-items: baseline;
}
.contacts-item-bg {
  position: absolute;
  left: -5%;
  top: -25%;
  width: 140px;
  z-index: -1;
}
.contacts-item-bg img {
  max-width: 140px;
}
.contacts-social {
  color: #272622;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}
.contacts-social img {
  margin-right: 20px;
}
.contacts-text a {
  color: #272622;
  display: block;
  margin-bottom: 5px;
}
.contacts-text a:hover {
  color: #cde449;
}
.contacts-social p {
  margin-bottom: 0;
  font-size: 30px;
  line-height: 35px;
  font-weight: 600;
}
.contacts-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.contacts-text {
  font-size: 30px;
  font-weight: 600;
}
.contacts-icon {
  min-width: 18px;
  margin-right: 15px;
}
.contacts-img {
  width: 390px;
  margin-left: 115px;
  margin-left: 0;
}
@media screen and (max-width: 1550px) {
  .contacts-item {
    margin-bottom: 70px;
  }
}
@media screen and (max-width: 1532px) {
  .contacts-item-bg img {
    height: 220px;
  }
}
@media screen and (max-width: 1190px) {
  .contacts-img {
    width: 100%;
  }
  .contacts-item:last-child {
    margin-top: 55px;
  }
}
@media screen and (max-width: 991px) {
  .contacts-item-bg img {
    height: 230px;
  }
}
@media screen and (max-width: 895px) {
  .contacts-item-bg img {
    height: 260px;
  }
  .contacts-item {
    margin-bottom: 100px;
  }
}
@media screen and (max-width: 767px) {
  .contacts-page .section {
    flex-direction: column-reverse;
  }
  .contacts-page {
    margin-top: -50px;
  }
  .contacts-img {
    width: 60%;
  }
  .contacts-item {
    margin-bottom: 100px;
  }
  .contacts-item:last-child {
    margin-top: 55px;
  }
}
@media screen and (max-width: 440px) {
  .contacts-text {
    font-size: 25px;
  }
  .contacts-social p {
    font-size: 25px;
  }
  .contacts-item-bg img {
    height: 240px;
  }
  .contacts-item:last-child {
    margin-top: 50px;
  }
}
/*reviews page*/
.reviews-item h3 {
  position: relative;
  letter-spacing: 0.25px;
  margin-bottom: 25px;
}
.reviews-item:last-child {
  margin-bottom: 165px;
}
.reviews-item h3:before {
  position: absolute;
  content: url(../img/material/lines.png);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  left: -5px;
}
.reviews-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.reviews-item {
  width: 22%;
  margin-left: 3%;
  margin-bottom: 110px;
}
.reviews-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.reviews-img:before {
  position: absolute;
  content: url('../img/play.svg');
  z-index: 2;
}
.reviews-img:hover:after {
  position: absolute;
  content: '';
  top: 24px;
  bottom: 24px;
  right: 20px;
  left: 20px;
  background-color: #000;
  opacity: 0.4;
  z-index: 1;
}
.reviews-img img {
  width: 100%;
  background-image: url('../img/reviews/bg.jpg');
  padding: 24px 20px;
}
@media screen and (max-width: 1199px) {
  .reviews-item {
    width: 30%;
  }
}
@media screen and (max-width: 991px) {
  .reviews-item {
    width: 47%;
  }
}
@media screen and (max-width: 767px) {
  .reviews-item {
    width: 100%;
  }
  .reviews-row {
    justify-content: center;
  }
}
/*cases page*/
.blog-cases {
  margin-bottom: 70px;
  max-width: 520px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-top: 20px;
  overflow: hidden;
  padding-right: 20px;
  cursor: pointer;
}
.blog-cases-wrapper::before {
  position: absolute;
  content: url('../img/material/lines3.png');
  top: -20px;
  right: -20px;
}
.blog-cases-img {
  height: 376px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 23px;
  position: relative;
  overflow: hidden;
}
.blog-cases-wrapper {
  max-width: 490px;
  position: relative;
}
.blog-cases-description {
  margin-bottom: 23px;
}
.blog-cases .btn-light {
  width: 250px;
  text-align: center;
}
.blog-cases:hover .blackout {
  opacity: 0.4;
}
.blog-cases:hover .blog-cases-img:before {
  position: absolute;
  content: url('../img/material/lines3.png');
  top: 13px;
  right: 22px;
  z-index: 5;
}
.blog-cases:hover .btn-light {
  background-color: #cde449;
}
.blog-cases:hover .btn-light span:first-child::before,
.blog-cases:hover .btn-light span:first-child::after,
.blog-cases:hover .btn-light span:last-child::before,
.blog-cases:hover .btn-light span:last-child::after {
  background-color: #b1c82e;
}
@media screen and (max-width: 991px) {
  .blog-cases {
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (max-width: 520px) {
  .blog-cases {
    max-height: 720px;
    width: 300px;
  }
  .blog-cases-img {
    height: 220px;
  }
}
/*case page */
.case-info-icon {
  max-width: 94px;
  min-width: 94px;
  width: 100%;
  margin-right: 11px;
}
.case-info-wrapper {
  display: flex;
  width: 100%;
  margin-right: 62px;
}
.case-info-img {
  background-color: #f5f6ec;
  padding-top: 30px;
  padding-bottom: 28px;
  padding-left: 20px;
  padding-right: 26px;
  min-width: 500px;
}
.case-info-img span {
  font-size: 20px;
  line-height: 60px;
  letter-spacing: 1.2px;
  color: #afc62c;
  text-transform: uppercase;
}
.case-info-name {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  letter-spacing: 0.3px;
  margin-bottom: 35px;
}
.case-info-block {
  display: flex;
  margin-bottom: 80px;
}
.case-info-text {
  line-height: 30px;
  padding-top: 70px;
  position: relative;
}
.case-info-text::before {
  position: absolute;
  content: '';
  height: 2px;
  width: 131px;
  background-color: #afc62c;
  top: 35px;
}
.case-info-img span,
.case-info-name {
  padding-left: 20px;
}
.case-item {
  display: flex;
}
.case-item img {
  min-width: 94px;
}
.case-item-block {
  display: flex;
  justify-content: space-between;
  margin-bottom: 127px;
}
.case-item-title {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.case-item-title span {
  font-size: 20px;
  line-height: 60px;
  letter-spacing: 1.2px;
  color: #afc62c;
  text-transform: uppercase;
  font-weight: 500;
  white-space: nowrap;
  padding-bottom: 10px;
  padding-top: 15px;
}
.case-item-description {
  background-color: #f5f6ec;
  font-family: 'Kelson Sans';
  font-size: 40px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: 1.2px;
  padding: 40px 30px 30px 30px;
  max-width: 250px;
  width: 100%;
  margin-right: auto;
}
.case-item + .case-item {
  margin-left: 40px;
}
@media screen and (max-width: 1680px) {
  .case-item-block {
    flex-wrap: wrap;
  }
  .case-item + .case-item {
    margin-left: 0;
  }
  .case-item {
    min-width: 400px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 991px) {
  .case-item {
    min-width: 500px;
  }
  .case-item-block {
    justify-content: center;
  }
  .case-info-block {
    flex-direction: column;
  }
}
@media screen and (max-width: 675px) {
  .case-info-img {
    min-width: auto;
  }
}
@media screen and (max-width: 576px) {
  .case-info-img {
    min-width: auto;
  }
  .case-item-title span {
    white-space: break-spaces;
    line-height: 40px;
  }
  .case-item {
    min-width: auto;
  }
  .case-item-block {
    justify-content: flex-start;
  }
  .case-info-wrapper {
    flex-direction: column;
  }
}
/*header*/
.menu-btn {
  cursor: pointer;
}
.menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 665px;
  padding-top: 208px;
  padding-left: 135px;
  transform: translateX(-200%);
  transition: 0.5s ease-in;
}
.menu-show {
  transform: translateX(0);
  z-index: 5;
}
.menu:before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #000000;
  opacity: 0.651;
  width: 665px;
  height: 100vh;
}
.menu ul li {
  font-size: 24px;
  margin-bottom: 35px;
  position: relative;
}
.menu ul li::before {
  position: absolute;
  content: '';
  height: 1px;
  width: 30px;
  background-color: #cde449;
  top: 50%;
  left: -20px;
  transform: translateX(-100%);
}
.menu ul {
  margin-left: 48px;
}
.logo {
  font-size: 46px;
  font-family: "Lato";
  color: rgba(130, 148, 25, 0);
  text-transform: uppercase;
  line-height: 0.978;
  text-align: left;
  text-shadow: 0.5px 0.866px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: 199.323px;
  top: 30.506px;
  z-index: 214;
}
.logo-wrapper {
  width: 100%;
}
.header {
  color: #fff;
  position: relative;
  padding-top: 35px;
  padding-bottom: 25px;
  z-index: 1;
  transition: 0.5s ease-in;
}
.header-main {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
}
.header-page {
  background-image: url('../img/footer-bg.jpg');
  padding-bottom: 55px;
}
.header-page:before {
  display: none;
}
.header:before {
  opacity: 0.149;
  text-shadow: 0.5px 0.866px 0px rgba(0, 0, 0, 0.2);
  background-color: #000000;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.header-phone {
  margin-right: 121px;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.header-phone span {
  font-size: 30px;
  line-height: 15px;
}
.header .section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logo-subtitle {
  font-size: 34px;
  line-height: 45px;
  white-space: nowrap;
}
.logo-block {
  display: flex;
}
.logo-block svg {
  margin-right: 15px;
}
.header-slider {
  transition: 0.4s ease-in;
}
.header-phone svg {
  margin-right: 21px;
  width: 30px;
}
.header-phone svg path {
  fill: #829419;
}
.header .btn:before {
  border: 1px solid #fff;
}
.header-contact {
  display: flex;
  align-items: center;
}
.logo-name {
  font-size: 46px;
  font-family: "Kelson Sans";
  font-weight: bold;
  text-transform: uppercase;
  color: transparent;
  -webkit-text-stroke: 2px #fff;
}
.header-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
}
@media screen and (max-width: 1199px) {
  .header-contact {
    flex-direction: column;
    align-items: flex-end;
  }
  .header-phone {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .header {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
@media screen and (max-width: 991px) {
  .banner {
    padding-top: 180px;
  }
}
@media screen and (max-width: 767px) {
  .header-phone span {
    display: none;
  }
  .header-contact {
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .logo-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
  .header-wrapper {
    flex-direction: column;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .logo-name {
    width: 100%;
    margin-right: 50px;
  }
  .banner-video {
    padding-top: 0;
    padding-bottom: 0;
  }
  .header-contact {
    margin-top: 15px;
  }
  .menu {
    width: 100%;
  }
  .menu:before {
    width: 100%;
  }
}
@media screen and (max-width: 440px) {
  .logo-subtitle {
    font-size: 26px;
  }
  .logo-name {
    font-size: 34px;
  }
  .menu {
    padding-left: 35px;
  }
}
@media screen and (max-width: 360px) {
  .btn {
    padding-left: 25px;
    padding-right: 25px;
  }
}
/*footer*/
footer {
  background-image: url('../img/footer-bg.jpg');
  font-size: 14px;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #7c7f78;
  padding-top: 38px;
  padding-bottom: 27px;
}
.footer a {
  color: #7c7f78;
}
.footer a:hover {
  color: #cde449;
  text-decoration: none;
}
.footer-social-item {
  display: flex;
  align-items: center;
  height: 33px;
  padding-left: 26px;
  padding-right: 26px;
  white-space: nowrap;
}
.footer-social-item:not(:first-child) {
  border-left: 1px solid #cde449;
}
.footer-social-item img {
  width: 35px;
  margin-right: 15px;
}
.footer-social {
  display: flex;
  align-items: center;
}
.footer-privacy-policy {
  border-bottom: 1px solid #cde449;
  padding-bottom: 5px;
}
.footer-logo {
  height: 25px;
}
@media screen and (max-width: 1500px) {
  .footer {
    flex-direction: column;
  }
  .footer-social {
    order: 1;
    margin-bottom: 15px;
  }
  .footer-privacy-policy {
    order: 2;
    margin-bottom: 15px;
  }
  .footer-copyright {
    order: 3;
    margin-bottom: 15px;
  }
  .footer-logo {
    order: 4;
  }
}
@media screen and (max-width: 650px) {
  .footer-social {
    flex-direction: column;
  }
  .footer-social-item:not(:first-child) {
    border-left: 1px solid transparent;
  }
}

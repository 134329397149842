/*photo page*/
.photo-card {
    padding-bottom: 85px;
    overflow: hidden;
    max-width: 530px;
    margin-left: auto;
    margin-right: auto;
}
.photo-card-img {
    background-size: auto;
    position: relative;
    background-repeat: no-repeat;
    display: block;
}

.photo-card-wrapper {
    position: relative;
    cursor: pointer;
    height: 100%;
    // overflow: hidden;
}
.photo-card-lines {
    height: 100%;
    position: absolute;
    right: 0;
}
.photo-card-img:before {
    position: absolute;
    content: url('../img/material/lines2.png');
    bottom: -7px;
    right: 32%;
}
.photo-card-img:after {
    position: absolute;
    content: url('../img/material/lines2.png');
    bottom: -12px;
    left: 32%;
}
.photo-card-wrapper:hover {
    .photo-card-title {
        color: #afc62c;
    }
    .blackout {
        opacity: .4;
    }
    .blackout:before {
        position: absolute;
        content: url('../img/material/lines2.png');
        bottom: 22px;
        right: 32%;
    }
    .blackout:after {
        position: absolute;
        content: url('../img/material/lines2.png');
        bottom: 16px;
        left: 32%;
    }
}
.photo-card-title {
    font-family: 'Harmonia Sans Pro Cyr Semi Bd';
    font-size: 25px;
    line-height: 30px;
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 65px;
    position: relative;
    margin-bottom: 19px;
}
.photo-card-title:before {
    position: absolute;
    content: '';
    width: 43px;
    height: 1px;
    background-color:  #afc62c;
    left: 0;
    top: 50%;
}

@media screen and (max-width:991px) {
    .photo-card {
        padding-bottom: 50px;
    }
}
@font-face {
    font-family: 'Kelson Sans';
    src: local('Kelson Sans Light RU'), local('KelsonSans-LightRU'), url('../fonts/Kelson/kelsonsanslightru.woff2') format('woff2'), url('../fonts/Kelson/kelsonsanslightru.woff') format('woff'), url('../fonts/Kelson/kelsonsanslightru.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Kelson Sans';
    src: local('Kelson Sans Regular RU'), local('KelsonSans-RegularRU'), url('../fonts/Kelson/kelsonsansregularru.woff2') format('woff2'), url('../fonts/Kelson/kelsonsansregularru.woff') format('woff'), url('../fonts/Kelson/kelsonsansregularru.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Kelson Sans';
    src: local('Kelson Sans Bold RU'), local('KelsonSans-BoldRU'), url('../fonts/Kelson/kelsonsansboldru.woff2') format('woff2'), url('../fonts/Kelson/kelsonsansboldru.woff') format('woff'), url('../fonts/Kelson/kelsonsansboldru.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-Black.eot');
    src: local('Harmonia Sans Pro Cyr Black'), local('HarmoniaSansProCyr-Black'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Black.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Black.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-BoldIt.eot');
    src: local('Harmonia Sans Pro Cyr Bold Italic'), local('HarmoniaSansProCyr-BoldIt'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BoldIt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BoldIt.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BoldIt.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BoldIt.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr Semi Bd';
    src: url('HarmoniaSansProCyr-SemiBdIt.eot');
    src: local('Harmonia Sans Pro Cyr Semi Bold Italic'), local('HarmoniaSansProCyr-SemiBdIt'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdIt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdIt.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdIt.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdIt.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-Light.eot');
    src: local('Harmonia Sans Pro Cyr Light'), local('HarmoniaSansProCyr-Light'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Light.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Light.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-BlackIt.eot');
    src: local('Harmonia Sans Pro Cyr Black Italic'), local('HarmoniaSansProCyr-BlackIt'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BlackIt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BlackIt.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BlackIt.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BlackIt.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-Bold.eot');
    src: local('Harmonia Sans Pro Cyr Bold'), local('HarmoniaSansProCyr-Bold'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Bold.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Bold.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-LightIt.eot');
    src: local('Harmonia Sans Pro Cyr Light Italic'), local('HarmoniaSansProCyr-LightIt'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-LightIt.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-LightIt.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-LightIt.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-LightIt.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr Cond';
    src: url('HarmoniaSansProCyr-BoldCond.eot');
    src: local('Harmonia Sans Pro Cyr Bold Cond'), local('HarmoniaSansProCyr-BoldCond'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BoldCond.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BoldCond.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BoldCond.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-BoldCond.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-Italic.eot');
    src: local('Harmonia Sans Pro Cyr Italic'), local('HarmoniaSansProCyr-Italic'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Italic.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Italic.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr Semi Bd';
    src: url('HarmoniaSansProCyr-SemiBd.eot');
    src: local('Harmonia Sans Pro Cyr Semi Bd'), local('HarmoniaSansProCyr-SemiBd'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBd.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBd.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBd.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBd.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr';
    src: url('HarmoniaSansProCyr-Regular.eot');
    src: local('Harmonia Sans Pro Cyr'), local('HarmoniaSansProCyr-Regular'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Regular.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Regular.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr SemiBdCn';
    src: url('HarmoniaSansProCyr-SemiBdCn.eot');
    src: local('Harmonia Sans Pro Cyr Semi Bold Cond'), local('HarmoniaSansProCyr-SemiBdCn'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdCn.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdCn.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdCn.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-SemiBdCn.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Harmonia Sans Pro Cyr Cond';
    src: url('HarmoniaSansProCyr-Cond.eot');
    src: local('Harmonia Sans Pro Cyr Cond'), local('HarmoniaSansProCyr-Cond'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Cond.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Cond.woff2') format('woff2'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Cond.woff') format('woff'),
        url('../fonts/Harmonia/HarmoniaSansProCyr-Cond.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html,
body {
    font-family: 'Harmonia Sans Pro Cyr';
    font-size: 18px;
    font-weight: 400;
    padding: 0;
    margin: 0;
    min-height: 100%;
    position: relative;
    color: #161d1f;
    line-height: 1;
}

/* fix for top margin collapsing and vertical scroll prevent for body tag, when body height = 100% */
body::before {
    content: "";
    display: block;
    width: 1px;
    height: 0px;
    background-color: transparent;
}

input,
textarea {
    font-family: @baseFont;
    font-size: 16px;
    line-height: 1.5;
    resize: vertical;
}

button,
input {
    outline: none !important;
}

button {
    background-color: transparent;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 400;
    line-height: 1;
}

.page h1 {
    font-size: 50px;
    line-height: 60px;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 3px;
}

h2 {
    font-size: 50px;
    margin-bottom: 56px;
    text-transform: uppercase;
    font-weight: 900;
    letter-spacing: 3px;
}

h3 {
    font-size: 25px;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: 14px;
}

ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

img {
    display: block;
    max-width: 100%;
}
.fixed {
    position: fixed;
}

a {
    color: #fff;
    text-decoration: none;
}

a:hover {
    color: #cde449;
    text-decoration: none;
}

.section {
    max-width: 1510px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 35px;
    padding-right: 35px;
}

.overflow {
    overflow: hidden;
}

.btn {
    background-color: #cde449;
    font-size: 18px;
    font-weight: 700;
    padding: 23px 52px 19px 49px;
    position: relative;
    white-space: nowrap;
    padding-top: 12px;
    transition: .3s ease-in;
}

.btn:hover {
    background-color: #afc62c;
    color: #fff;
    z-index: 5;
}

.btn:hover:after {
    z-index: 1;
}

.btn:after {
    position: absolute;
    background-color: ;
    content: '';
    border: 1px solid #cde449;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.btn:before {
    position: absolute;
    content: '';
    border: 1px solid #777777;
    top: -6px;
    left: -6px;
    right: 6px;
    bottom: 6px;
}

input.btn-light {
    background-color: #fff;
}
.btn-light {
    border: 1px solid #cde449;
    padding-top: 25px;
    padding-bottom: 22px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 16px;
    font-weight: 700;
    position: relative;
    display: inline-block;
    transition: .3s ease-in;
    white-space: nowrap;
}
.blog-card:hover .btn-light:before {
    position: absolute;
    content: url('../img/angle-right.svg');
    left: 10%;
}
.services-item:hover .btn-light:before {
    position: absolute;
    content: url('../img/angle-right.svg');
    left: 10%;
}
.blog-cases:hover .btn-light:before {
    position: absolute;
    content: url('../img/angle-right.svg');
    left: 10%;
}
.btn-light span {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.btn-light span:first-child::before {
    position: absolute;
    content: '';
    width: 75px;
    height: 2px;
    background-color: #cde449;
    position: absolute;
    left: 0;
    top: 0;
}

.btn-light span:first-child::after {
    position: absolute;
    content: '';
    width: 75px;
    height: 2px;
    background-color: #cde449;
    position: absolute;
    right: 0;
    bottom: 0;
}

.btn-light span:last-child::before {
    position: absolute;
    content: '';
    width: 2px;
    height: 40px;
    background-color: #cde449;
    position: absolute;
    left: 0;
    top: 0;
}

.btn-light span:last-child::after {
    position: absolute;
    content: '';
    width: 2px;
    height: 40px;
    background-color: #cde449;
    position: absolute;
    right: 0;
    bottom: 0;
}

.btn-light:hover {
    background-color: #cde449;
}

.btn-light:hover span:first-child::before,
.btn-light:hover span:first-child::after,
.btn-light:hover span:last-child::before,
.btn-light:hover span:last-child::after {
    background-color: #b1c82e;
}

.breadcrumbs {
    color: #fff;
    background-image: url('../img/footer-bg.jpg');
    margin-bottom: 74px;
    padding-bottom: 115px;
    position: relative;
    overflow: hidden;
}

.breadcrumbs .section:before {
    position: absolute;
    content: url('../img/line.png');
    bottom: 50px;
    left: 0;
}

.breadcrumbs ul {
    display: flex;
    align-items: center;
    padding-bottom: 48px;
    padding-top: 10px;
}

.breadcrumbs ul li {
    padding-right: 40px;
    position: relative;
}

.breadcrumbs ul li a {
    text-decoration: underline;
}

.breadcrumbs ul li svg {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
}

.breadcrumbs ul li:last-of-type {
    color: #98978f;
    text-decoration: none;
}
.pagination {
    justify-content: center;
}
.page-link {
    color: #2b272e;
    border: none;
    width: 49px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.page-item.active .page-link {
    border-radius: 50%;
    background-color: #cde449;
    color: #2b272e;
    padding: 0;
    margin-right: 5px;
    margin-left: 5px;
}
.page-link:hover {
    border-radius: 50%;
    background-color: #cde449;
    color: #2b272e;
    border: none;
    outline: none!important;
}
.page-link:focus {
    box-shadow: none;
}
.page-item:first-child .page-link {
    border-radius: 50%;
}
.page-item:last-child .page-link {
    border-radius: 50%;
}
.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor: pointer;
}

.close:hover {
    opacity: 1;
}

.close:before,
.close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #fff;
}

.close:before {
    transform: rotate(45deg);
}

.close:after {
    transform: rotate(-45deg);
}
video {
    position:fixed;
    z-index:-1;
    min-width:100%;
    min-height: 100%;
    overflow:hidden;
 }
 .owl-carousel .owl-item img {
     width: auto;
 }
@media screen and (max-width:520px) {
    h2 {
        font-size: 25px;
        margin-bottom: 26px;
    }

    .section {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (max-width:450px) {
    .page h1 {
        font-size: 35px;
        line-height: 50px;
    }

    .breadcrumbs ul {
        flex-wrap: wrap;
        line-height: 30px;
    }
    .page-link {
        width: 40px;
        height: 40px;
    }
    .footer-copyright {
        text-align: center;
    }
}
@media (min-width: 576px) {
    .custom .container {
        max-width: 100%;
    }
}
@media (min-width: 768px) {
    .custom .container {
        max-width: 950px;
    }
}
@media (min-width: 992px) {
    .custom .container {
        max-width: 1050px;
    }
}
@media (min-width: 1200px) {
    .custom .container {
        max-width: 1720px;
    }
}
@media (min-width: 992px) and (max-width: 1420px) {
    .custom .col-xl-4 {
        flex: 0 0 50%;
        max-width: 50%;
    }
}


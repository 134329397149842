/*case page */
.case-info-icon {
    max-width: 94px;
    min-width: 94px;
    width: 100%;
    margin-right: 11px;
}
.case-info-wrapper {
    display: flex;
    width: 100%;
    margin-right: 62px;
}
.case-info-img {
    background-color: #f5f6ec;
    padding-top: 30px;
    padding-bottom: 28px;
    padding-left: 20px;
    padding-right: 26px;
    min-width: 500px;
}
.case-info-img span {
    font-size: 20px;
    line-height: 60px;
    letter-spacing: 1.2px;
    color: #afc62c;
    text-transform: uppercase;
}
.case-info-name {
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
    letter-spacing: .3px;
    margin-bottom: 35px;
}
.case-info-block {
    display: flex;
    margin-bottom: 80px;
}
.case-info-text {
    line-height: 30px;
    padding-top: 70px;
    position: relative;
}
.case-info-text::before {
    position: absolute;
    content: '';
    height: 2px;
    width: 131px;
    background-color: #afc62c;
    top: 35px;
}
.case-info-img span,
.case-info-name {
    padding-left: 20px;
}
.case-item {
    display: flex;
}
.case-item img {
    min-width: 94px;
}
.case-item-block {
    display: flex;
    justify-content: space-between;
    margin-bottom: 127px;
}
.case-item-title {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.case-item-title span {
    font-size: 20px;
    line-height: 60px;
    letter-spacing: 1.2px;
    color: #afc62c;
    text-transform: uppercase;
    font-weight: 500;
    white-space: nowrap;
    padding-bottom: 10px;
    padding-top: 15px;
}
.case-item-description {
    background-color: #f5f6ec;
    font-family: 'Kelson Sans';
    font-size: 40px;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 1.2px;
    padding: 40px 30px 30px 30px;
    max-width: 250px;
    width: 100%;
    margin-right: auto;
}
.case-item + .case-item {
    margin-left: 40px;
}


@media screen and (max-width:1680px) {
    .case-item-block {
        flex-wrap: wrap;
    }
    .case-item + .case-item {
        margin-left: 0;
    }
    .case-item {
        min-width: 400px;
        margin-bottom: 20px;
    }
}
@media screen and (max-width:991px) {
    .case-item {
        min-width: 500px;
    }
    .case-item-block {
        justify-content: center;
    }
    .case-info-block {
        flex-direction: column;
    }
}
@media screen and (max-width:675px) {
    .case-info-img {
        min-width: auto;
    }
}
@media screen and (max-width:576px) {
    .case-info-img {
        min-width: auto;
    }
    .case-item-title span {
        white-space: break-spaces;
        line-height: 40px;
    }
    .case-item {
        min-width: auto;
    }
    .case-item-block {
        justify-content: flex-start;
    }
    .case-info-wrapper {
        flex-direction: column;
    }
}
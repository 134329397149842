/*header*/
.menu-btn {
    cursor: pointer;
}
.menu {
    position: absolute;
    top: 0;
    left: 0;
    width: 665px;
    padding-top: 208px;
    padding-left: 135px;
    transform: translateX(-200%);
    transition: .5s ease-in;
}
.menu-show {
    transform: translateX(0);
    z-index: 5;
}
.menu:before {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgb(0, 0, 0);
    opacity: 0.651;
    width: 665px;
    height: 100vh;
}
.menu ul li {
    font-size: 24px;
    margin-bottom: 35px;
    position: relative;
}
.menu ul li::before {
    position: absolute;
    content: '';
    height: 1px;
    width: 30px;
    background-color:#cde449;
    top: 50%;
    left: -20px;
    transform: translateX(-100%);
}
.menu ul {
    margin-left: 48px;
}
.logo {
    font-size: 46px;
    font-family: "Lato";
    color: rgba(130, 148, 25, 0);
    text-transform: uppercase;
    line-height: 0.978;
    text-align: left;
    text-shadow: 0.5px 0.866px 0px rgba(0, 0, 0, 0.2);
    position: absolute;
    left: 199.323px;
    top: 30.506px;
    z-index: 214;
}
.logo-wrapper {
    width: 100%;
}
.header {
    color: #fff;
    position: relative;
    padding-top: 35px;
    padding-bottom: 25px;
    z-index: 1;
    transition: .5s ease-in;
}
.header-main {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 5;
}
.header-page {
    background-image: url('../img/footer-bg.jpg');
    padding-bottom: 55px;
}
.header-page:before {
    display: none;
}
.header:before {
    opacity: 0.149;
    text-shadow: 0.5px 0.866px 0px rgba(0, 0, 0, 0.2);
    background-color: rgb(0, 0, 0);
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.header-phone {
    margin-right: 121px;
    white-space: nowrap;
    display: flex;
    align-items: center;
}
.header-phone span {
    font-size: 30px;
    line-height: 15px;
}

.header .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo-subtitle {
    font-size: 34px;
    line-height: 45px;
    white-space: nowrap;
}

.logo-block {
    display: flex;
}

.logo-block svg {
    margin-right: 15px;
}
.header-slider {
    transition: .4s ease-in;
}
.header-phone svg {
    margin-right: 21px;
    width: 30px;
}

.header-phone svg path {
    fill: #829419;
}

.header .btn:before {
    border: 1px solid #fff;
}
.header-contact {
    display: flex;
    align-items: center;
}
.logo-name {
    font-size: 46px;
    font-family: "Kelson Sans";
    font-weight: bold;
    text-transform: uppercase;
    color: transparent;
    -webkit-text-stroke: 2px #fff;
}
.header-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
}
@media screen and (max-width:1199px) {
    .header-contact {
        flex-direction: column;
        align-items: flex-end;
    }
    .header-phone {
        margin-right: 0;
        margin-bottom: 30px;
    }
    .header {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}
@media screen and (max-width:991px) {
    .banner {
        padding-top: 180px;
    }
}
@media screen and (max-width:767px) {
    .header-phone span {
        display: none;
    }
    .header-contact {
        flex-direction: row;
        width: 100%;
        justify-content: center;
    }
    .logo-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .header-wrapper {
        flex-direction: column;
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .logo-name {
        width: 100%;
        margin-right: 50px;
    }
    .banner-video {
        padding-top: 0;
        padding-bottom: 0;
    }
    .header-contact {
        margin-top: 15px;
    }
    
    .menu {
        width: 100%;
    }
    .menu:before {
        width: 100%;
    }
}
@media screen and (max-width:440px) {
    .logo-subtitle {
        font-size: 26px;
    }
    .logo-name {
        font-size: 34px;
    }
    .menu {
        padding-left: 35px;
    }
}
@media screen and (max-width:360px) {
    .btn {
        padding-left: 25px;
        padding-right: 25px;
    }
}

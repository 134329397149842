/*reviews page*/
.reviews-item h3 {
    position: relative;
    letter-spacing: .25px;
    margin-bottom: 25px;
}
.reviews-item:last-child {
    margin-bottom: 165px;
}
.reviews-item h3:before {
    position: absolute;
    content: url(../img/material/lines.png);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: -5px;
}
.reviews-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.reviews-item {
    width: 22%;
    margin-left: 3%;
    margin-bottom: 110px;
}
.reviews-img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.reviews-img:before {
    position: absolute;
    content: url('../img/play.svg');
    z-index: 2;
}
.reviews-img:hover:after  {
    position: absolute;
    content: '';
    top: 24px;
    bottom: 24px;
    right: 20px;
    left: 20px;
    background-color: #000;
    opacity: .4;
    z-index: 1;
}

.reviews-img img {
    width: 100%;
    background-image: url('../img/reviews/bg.jpg');
    padding: 24px 20px;
}

@media screen and (max-width:1199px) {
    .reviews-item {
        width: 30%;
    }
}

@media screen and (max-width:991px) {
    .reviews-item {
        width: 47%;
    }
}

@media screen and (max-width:767px) {
    .reviews-item {
        width: 100%;
    }
    .reviews-row {
        justify-content: center;
    }
}
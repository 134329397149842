/*contacts page*/
.contacts-page {
    margin-bottom: 27px;
}
.contacts-page .section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.contacts-item {
    position: relative;
    max-width: 350px;
    margin-left: 15px;
}
.contacts-address {
    display: flex;
    align-items: baseline;
    margin-bottom: 20px;
}
.contacts-phone {
    display: flex;
    align-items: baseline;
}
.contacts-item-bg {
    position: absolute;
    left: -5%;
    top: -25%;
    width: 140px;
    z-index: -1;
}
.contacts-item-bg img {
    max-width: 140px;
}
.contacts-social {
    color: #272622;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}
.contacts-social img {
    margin-right: 20px;
}
.contacts-text a {
    color: #272622;
    display: block;
    margin-bottom: 5px;
}
.contacts-text a:hover {
    color: #cde449;
}
.contacts-social p {
    margin-bottom: 0;
    font-size: 30px;
    line-height: 35px;
    font-weight: 600;
}
.contacts-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
}
.contacts-text {
    font-size: 30px;
    font-weight: 600;
}
.contacts-icon {
    min-width: 18px;
    margin-right: 15px;
}
.contacts-img {
    width: 390px;
    margin-left: 115px;
    margin-left: 0;
}
@media screen and (max-width:1550px) {
    .contacts-item {
        margin-bottom: 70px;
    }
}
@media screen and (max-width:1532px) {
    .contacts-item-bg img {
        height: 220px;
    }
}

@media screen and (max-width:1190px) {
    .contacts-img {
        width: 100%;
    }
    .contacts-item:last-child {
        margin-top: 55px;
    }
}

@media screen and (max-width:991px) {
    .contacts-item-bg img {
        height: 230px;
    }   
}
@media screen and (max-width:895px) {
    .contacts-item-bg img {
        height: 260px;
    }
    .contacts-item {
        margin-bottom: 100px;
    }
}
@media screen and (max-width:767px) {
    .contacts-page .section {
        flex-direction: column-reverse;
    }
    .contacts-page {
        margin-top: -50px;
    }
    .contacts-img {
        width: 60%;
    }
    .contacts-item {
        margin-bottom: 100px;
    }
    .contacts-item:last-child {
        margin-top: 55px;
    }
}
@media screen and (max-width:440px) {
    .contacts-text {
        font-size: 25px;
    }
    .contacts-social p {
        font-size: 25px;
    }
    .contacts-item-bg img {
        height: 240px;
    }
    .contacts-item:last-child {
        margin-top: 50px;
    }
}

/*about page*/
.about-page p {
    font-weight: 600;
    font-size: 24px;
    line-height: 35px;
}
.about-page h2 {
    margin-top: 110px;
}
.benefits-item {
    margin-right: 25px;
    margin-bottom: 30px;
}
.benefits-item-img {
    width: 92px;
    min-width: 92px;
    margin-right: 25px;
}
.benefits-item-wrpper {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}
.benefits-item-title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 600;
    color: #afc62c;
    text-transform: uppercase;
    letter-spacing: .6px;
}
.benefits-item-description {
    position: relative;
    padding: 45px 10px 30px 18px;
    background-color: #f5f6ec;
    color: #6c6b5f;
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    max-width: 335px;
}
.benefits-item-description:before {
    position: absolute;
    content: '';
    width: 61px;
    height: 1px;
    background-color: #afc62c;
    top: 25px;
}
.benefits-block {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
}
.partners-item {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}
.partners-item-img {
    border: 10px solid #dddbd1;
    width: 310px;
    height: 138px;
    margin-right: 25px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}
.partners-item-img img {
    height: auto;
    width: auto;
    margin-left: auto;
    margin-right: auto;
}
.partners-block {
    margin-bottom: 135px;
    display: flex;
        justify-content: center;
}
.partners-block .partners-item:first-child {
    height: 340px;
}
.partners-block .partners-item:first-child .partners-item-img {
    height: 100%;
}
.partners-block .partners-item:first-child .partners-item-img img {
    height: 240px;
    width: auto;
}
@media screen and (max-width:1720px) {
    .partners-block {
        flex-wrap: wrap;
    }
    .partners-block .partners-item:last-child {
        flex-direction: row;
    }
}
@media screen and (max-width:1530px) {
    .benefits-item {
        width: 33.3%;
    }
    .benefits-block {
        justify-content: center;
        flex-wrap: wrap;
    }
}
@media screen and (max-width:1250px) {
    .benefits-block {
        justify-content: space-around;
    }
}
@media screen and (max-width:1024px) {
    .benefits-item {
        width: 40%;
    }
}
@media screen and (max-width:930px) {
    .benefits-item {
        width: 51%;
    }
}
@media screen and (max-width:767px) {
    .benefits-block {
        justify-content: flex-start;
    }
    .partners-block {
        margin-bottom: 50px;
    }
    .about-page h2 {
        margin-top: 50px;
    }
    
    .partners-item-img {
        margin-right: 5px;
    }
}
@media screen and (max-width:699px) {
    .partners-block .partners-item:last-child {
        flex-direction: column;
    }
}
@media screen and (max-width:540px) {
    .partners-item-img {
        margin-right: 0;
    }
    .benefits-item-img {
        width: 80px;
        min-width: 80px;
        margin-right: 20px;
    }
    .benefits-item {
        width: 100%;
    }
    .benefits-item-title {
        font-size: 19px;
        line-height: 30px;
        font-weight: 600;
    }
}

/*single blog*/
.blog-single {
    overflow: hidden;
    margin-top: -24px;
}
.blog-single h3 {
    font-size: 30px;
    line-height: 45px;
    color: #afc62c;
    font-weight: 900;
    letter-spacing: 3px;
}
.blog-single-wrapper {
    display: flex;
    line-height: 30px;
    margin-bottom: 50px;
    align-items: center;
}
.blog-single-wrapper > .blog-single-block {
    margin-right: 30px;
}
.blog-single-wrapper > .blog-single-img {
    margin-right: 30px;
}
.blog-single-img {
    min-width: 390px;
    height: 100%;
    background-color: #f5f6ec;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.blog-single-block .commercial-list li {
    font-size: 16px;
    padding-bottom: 17px;
}
.blog-date {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 50px;
    position: relative;
    color: #6c6b5f;
    display: flex;
    align-items: center;
    height: 54px;
}
.blog-date-bg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -5px;
    z-index: -1;
}
.blog-date-bg img {
    height: 54px;
    width: 17px;
}
.blog-single .blog-page {
    position: relative;
    margin-bottom: 0;
}
.blog-single .blog-page h2 {
    margin-top: 140px;
    position: relative;
}
.blog-single .blog-page h2::before {
    position: absolute;
    content: url('../img/material/lines2.png');
    top: -140px;
    left: 0;

}
.blog-single .blog-page h2::after {
    position: absolute;
    content: url('../img/material/lines2.png');
    top: -120px;
    left: 170px;
}
@media screen and (max-width:991px) {
    .blog-single-wrapper:nth-child(2n) {
        flex-direction: column;
    }
    .blog-single-wrapper:nth-child(2n+1) {
        flex-direction: column-reverse;
    }
    .blog-single-img {
        margin-bottom: 25px;
    }
    .blog-single-wrapper > .blog-single-img {
        margin-right: 0;
    }
    .blog-single-wrapper > .blog-single-block {
        margin-right: 0;
    }
    .blog-single-img {
        min-width: auto;
    }
    .blog-single-img img {
        max-width: 90%;
    }
}